/* global ajax_object */
import XLSX from 'xlsx';
import { createTable } from '../default/createTable';

const lang = document.documentElement.lang.slice(0, 2);

const appendLiquidityCondition = (tableContainer) => {
  // eslint-disable-next-line no-undef
  const xmls = ajax_object.data_body;

  const tableTitle = document.createElement('h3');
  tableTitle.innerText = lang === 'pl' ? 'Dane w mln PLN' : 'Data in mln PLN';

  tableContainer.appendChild(tableTitle);

  for (const key in xmls) {
    // eslint-disable-next-line no-prototype-builtins
    if (xmls.hasOwnProperty(key)) {
      if (key.indexOf('Sytuacja_plynnosciowa') > -1) {
        let data = xmls[key],
          workbook = XLSX.read(
            data, {
              type: 'string',
              raw: true,
              cellText: false,
              cellDates: true,
              codepage: 65001
            }),
          firstSheetName = workbook.SheetNames[0],
          firstSheet = workbook.Sheets[firstSheetName],
          sheetRange;

        if (lang === 'pl') {
          sheetRange = 2;
        } else {
          sheetRange = 3;
        }

        const csvObj = XLSX.utils.sheet_to_row_object_array(firstSheet, {
          FS: ';',
          RS: '\n',
          raw: false,
          range: sheetRange,
          dateNF: 'yyyy"-"mm"-"dd',
        });

        tableContainer.appendChild(createTable(csvObj, 'csv'));
      }
    }
  }
};

export { appendLiquidityCondition };
