/* global ajax_object */
import xml2js from 'xml2js';

const lang = document.documentElement.lang.slice(0, 2);

const appendBon = (listContainer) => {
  // eslint-disable-next-line no-undef
  const xmls = ajax_object.data_body,
    parser = new xml2js.Parser();

  for (const key in xmls) {
    // eslint-disable-next-line no-prototype-builtins
    if (xmls.hasOwnProperty(key)) {
      if (key.indexOf('bony/dir') > -1) {
        // eslint-disable-next-line no-undef
        parser.parseStringPromise(xmls[key]).then(function (result) {
          const files = result.files.file;

          // START Generate list of archive files
          const listTitle = document.createElement('h3');
          listTitle.innerText = lang === 'pl' ? 'Pliki do pobrania' : 'Files to download';

          listContainer.appendChild(listTitle);

          let fileList = document.createElement('ul');

          files.forEach((file, key, arr) => {
            const reverseFile = arr[arr.length - key - 1],
              fileItem = document.createElement('li'),
              fileLink = document.createElement('a'),
              fileName = ((reverseFile.$.name.replace('bony', ' ')).replaceAll('.xlsx', ' ').replaceAll('Bony', ' '));

            fileLink.setAttribute('href', reverseFile._.trim());
            fileLink.setAttribute('class', 'card-file');
            fileLink.innerText = lang === 'pl' ? 'Operacje NBP - ' + fileName : 'NBP operations - ' + fileName;

            fileItem.appendChild(fileLink);
            fileList.appendChild(fileItem);
            listContainer.appendChild(fileList);
          });
          // END Generate list of archive files
        });
      }
    }
  }
};

export { appendBon };
