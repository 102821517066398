import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

const prevArrowSVGString = '<svg width="15" height="11" viewBox="0 0 15 11" fill="none"' +
  ' xmlns="http://www.w3.org/2000/svg">' +
  '<path d="M5.80189 2.00085L2 5.80273L5.80189 9.60462" stroke="#152E52" stroke-width="1.5" stroke-linecap="square"/>' +
  '<path d="M15 5.80273L2 5.80274" stroke="#152E52" stroke-width="1.5"/></svg>';
const nextArrowSVGString = '<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <path d="M9.19811 2.00085L13 5.80273L9.19811 9.60462" stroke="#152E52" stroke-width="1.5" stroke-linecap="square"/>\n' +
  '    <path d="M0 5.80273L13 5.80274" stroke="#152E52" stroke-width="1.5"/>\n' +
  '</svg>\n';

export function initPhotoSwipeFromDOM (gallerySelector) {
  const options = {
    arrowPrevSVG: prevArrowSVGString,
    arrowNextSVG: nextArrowSVGString,
    gallery: gallerySelector,
    bgOpacity: 0.5,
    padding: { top: 30, bottom: 30, left: 30, right: 30 },
    initialZoomLevel: 'fit',
    zoom: false,
    imageClickAction: false,
    pswpModule: () => import('photoswipe'),
    close: false,
  };

  // Conditionally add `children` property if `gallerySelector` is not a tag
  if (typeof gallerySelector === 'string' && gallerySelector.contains('fancybox-img')) {
    delete options.children;
  } else {
    options.children = 'a';
  }

  const lightbox = new PhotoSwipeLightbox(options);
  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.gallery-caption');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }
          el.innerHTML = captionHTML || '';
        });
      }
    });
  });

  new PhotoSwipeDynamicCaption(lightbox, {
    captionContent: (slide) => {
      return slide.data.element.querySelector('img').getAttribute('alt');
    },
    type: 'below'
  });

  lightbox.on('contentAppend', ({ content }) => {
    // Create a new close button element
    let closeButton = document.createElement('button');
    closeButton.classList.add('pswp-close-button');

    // Add styles to the close button
    closeButton.style.position = 'absolute';
    closeButton.style.top = '20px';
    closeButton.style.left = (content.displayedImageWidth - 50) + 'px';
    closeButton.style.width = '25px';
    closeButton.style.height = '25px';
    closeButton.style.zIndex = '9999';
    closeButton.style.background = 'rgba(0, 0, 0, 0.5)';
    closeButton.style.color = 'white';
    closeButton.style.border = '1px solid #7DAFE1';
    closeButton.style.borderRadius = '6px';
    closeButton.style.cursor = 'pointer';

    // Append the close button to the zoom wrap
    content.slide?.container.appendChild(closeButton);

    // Add event listener to close button to close the lightbox
    closeButton.addEventListener('click', function () {
      lightbox.pswp.close(); // Assuming the lightbox object has a close() method
    });

    // Fix bug with all captions below
    const captionsBelow = content.slide?.container.querySelectorAll('.pswp__dynamic-caption--below');
    if (captionsBelow) {
      captionsBelow.forEach(captionBelow => {
        let currentWidth = captionBelow.clientWidth;
        let newWidth = currentWidth - 1;
        captionBelow.style.width = newWidth + 'px';
      });
    }

    // Fix bug with all captions below
    const imageWrapper = content.slide?.container.querySelector('.pswp__zoom-wrap');
    if (imageWrapper) {
      imageWrapper.style.width = content.displayedImageWidth;
      imageWrapper.style.height = content.displayedImageHeight;
    }
  });

  // Change close button position on image rezsize
  lightbox.on('imageSizeChange', ({ content, width }) => {
    const closeButton = content.slide?.container.querySelector('.pswp-close-button');
    if (closeButton) {
      closeButton.style.left = (width - 50) + 'px';
    }
  });

  // Change close button position on image rezsize
  lightbox.on('contentResize', ({ content }) => {
    // Ensure there's a smooth transition effect
    content.element.style.transition = 'padding 0.3s ease-in-out';
    // Subtract 16px from both width and height
    content.element.style.padding = '16px';
  });

  lightbox.init();
}
