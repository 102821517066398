import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

const initOffcanvasToggling = () => {
  const togglerButton = document.getElementById('navbar-toggler-desktop');
  const togglerButtonMobile = document.getElementById('navbar-toggler-mobile');
  const menuContainer = document.querySelector('.nav-mobile');
  const mainMobileContainer = document.querySelector('.nav-mobile');

  const toggleMenu = (offCanvas, container) => {
    const mainNav = document.getElementById('nav-header');
    const offCanvasSelector = offCanvas.dataset.bsTarget;
    const offCanvasElement = document.getElementById(offCanvasSelector.replace('#', ''));
    if (offCanvasElement) {
      offCanvasElement.addEventListener('show.bs.collapse', function () {
        offCanvasElement.classList.add('offcanvas--show');
        mainNav.classList.add('navbar--offcanvas');
      });
      offCanvasElement.addEventListener('hide.bs.collapse', function () {
        offCanvasElement.classList.remove('offcanvas--show');
        mainNav.classList.remove('navbar--offcanvas');
      });
      offCanvasElement.addEventListener('shown.bs.collapse', function () {
        const isHidden = !offCanvasElement.classList.contains('offcanvas--show');
        offCanvasElement.getAttribute('aria-hidden', isHidden);
        offCanvas.getAttribute('aria-expanded', !isHidden);
        isHidden ? enableBodyScroll(container) : disableBodyScroll(container);
      });
      offCanvasElement.addEventListener('hidden.bs.collapse', function () {
        const isHidden = !offCanvasElement.classList.contains('offcanvas--show');
        offCanvasElement.getAttribute('aria-hidden', isHidden);
        offCanvas.getAttribute('aria-expanded', !isHidden);
        isHidden ? enableBodyScroll(container) : disableBodyScroll(container);
      });
    }
  };
  if (togglerButton !== null) {
    togglerButton.addEventListener('click', toggleMenu(togglerButton, menuContainer));
  }
  if (togglerButtonMobile !== null) {
    togglerButtonMobile.addEventListener('click', toggleMenu(togglerButtonMobile, mainMobileContainer));
  }
};

export { initOffcanvasToggling };

