const setArrNames = (arr) => {
  arr['kw_1'] = {
    'pl': 'Kursy miesięczne średnioważone EUR, USD i CHF',
    'en': 'Weighted average monthly exchage rates of EUR, USD and CHF',
  };
  arr['kw_1_1'] = {
    'pl': 'EUR - miesięczny średnioważony kurs walutowy w PLN',
    'en': 'EUR - weighted average exchange rate in PLN',
  };
  arr['kw_1_2'] = {
    'pl': 'USD - miesięczny średnioważony kurs walutowy w PLN',
    'en': 'USD - weighted average exchange rate in PLN',
  };
  arr['kw_1_3'] = {
    'pl': 'CHF - miesięczny średnioważony kurs walutowy w PLN',
    'en': 'CHF - weighted average exchange rate in PLN',
  };
  arr['mak_1'] = { 'pl': 'Inflacja (r/r)', 'en': 'Inflation' };
  arr['mak_1_1'] = { 'pl': 'CPI (r/r) ', 'en': 'CPI (y/y)' };
  arr['mak_2'] = { 'pl': 'Inflacja bazowa (r/r)', 'en': 'Core inflation (y/y)' };
  arr['mak_2_1'] = { 'pl': 'CPI (r/r) ', 'en': 'CPI (y/y)' };
  arr['mak_2_2'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen administrowanych (r/r)',
    'en': 'Core inflation, net of administered prices (y/y)',
  };
  arr['mak_2_3'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen najbardziej zmiennych (r/r)',
    'en': 'Core inflation, net of most volatile prices (y/y)',
  };
  arr['mak_2_4'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen żywności i energii (r/r)',
    'en': 'Core inflation, net of food and energy prices (y/y)',
  };
  arr['mak_2_5'] = {
    'pl': 'Inflacja bazowa 15% średnia obcięta (r/r)',
    'en': 'Core inflation, 15% trimmed mean (y/y)',
  };
  arr['mak_3'] = { 'pl': 'Inflacja bazowa (m/m)', 'en': 'Core inflation (m/m)' };
  arr['mak_3_1'] = { 'pl': 'CPI (m/m)', 'en': 'CPI (m/m)' };
  arr['mak_3_2'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen administrowanych (m/m)',
    'en': 'Core inflation, net of administered prices (m/m)',
  };
  arr['mak_3_3'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen najbardziej zmiennych (m/m)',
    'en': 'Core inflation, net of most volatile prices (m/m)',
  };
  arr['mak_3_4'] = {
    'pl': 'Inflacja bazowa po wyłączeniu cen żywności i energii (m/m)',
    'en': 'Core inflation, net of food and energy prices (m/m)',
  };
  arr['mak_3_5'] = {
    'pl': 'Inflacja bazowa 15% średnia obcięta (m/m)',
    'en': 'Core inflation, 15% trimmed mean (m/m)',
  };
  arr['mak_4'] = { 'pl': 'Oczekiwania inflacyjne konsumentów', 'en': 'Consumer inflation expectations' };
  arr['mak_4_1'] = {
    'pl': 'Oczekiwania inflacyjne konsumentów - statystyka bilansowa',
    'en': 'Consumer inflation expectations - balance statistics',
  };
  arr['mak_5'] = { 'pl': 'Oczekiwania inflacyjne przedsiębiorstw', 'en': 'Inflation expectations of enterprises' };
  arr['mak_5_1'] = {
    'pl': 'Oczekiwania inflacyjne przedsiębiorstw - statystyka bilansowa',
    'en': 'Inflation expectations of enterprises - balance statistics',
  };
  arr['sp_1'] = {
    'pl': 'Podstawowe stopy procentowe NBP na koniec miesiąca',
    'en': 'NBP interest rates, end of month',
  };
  arr['sp_1_1'] = { 'pl': 'Stopa referencyjna', 'en': 'Reference rate' };
  arr['sp_1_2'] = { 'pl': 'Stopa lombardowa', 'en': 'Lombard rate' };
  arr['sp_1_3'] = { 'pl': 'Stopa depozytowa', 'en': 'Deposit rate' };
  arr['sp_2'] = {
    'pl': 'Oprocentowanie depozytów - gospodarstwa domowe',
    'en': 'Interest rates on deposits - households',
  };
  arr['sp_2_1'] = { 'pl': 'Depozyty bieżące', 'en': 'Overnight deposits' };
  arr['sp_2_2'] = { 'pl': 'Depozyty ogółem  - stany', 'en': 'Total deposits - outstanding amounts' };
  arr['sp_2_3'] = {
    'pl': 'Depozyty ogółem  - umowy nowe i renegocjowane',
    'en': 'Total deposits - new and renegotiated business',
  };
  arr['sp_3'] = {
    'pl': 'Oprocentowanie depozytów - przedsiębiorstwa',
    'en': 'Interest rates on deposits - non-financial corporations',
  };
  arr['sp_3_1'] = { 'pl': 'Depozyty bieżące', 'en': 'Overnight deposits' };
  arr['sp_3_2'] = { 'pl': 'Depozyty ogółem  - stany', 'en': 'Total deposits - outstanding amounts' };
  arr['sp_3_3'] = {
    'pl': 'Depozyty ogółem  - umowy nowe i renegocjowane',
    'en': 'Total deposits - new and renegotiated business',
  };
  arr['sp_4'] = { 'pl': 'Oprocentowanie kredytów - gospodarstwa domowe', 'en': 'Interest rates on loans - households' };
  arr['sp_4_1'] = { 'pl': 'Kredyty konsumpcyjne - stany', 'en': 'Loans for consumption - outstanding amounts' };
  arr['sp_4_2'] = {
    'pl': 'Kredyty konsumpcyjne - umowy nowe i renegocjowane',
    'en': 'Loans for consumption - new and renegotiated business',
  };
  arr['sp_4_3'] = { 'pl': 'Kredyty mieszkaniowe - stany', 'en': 'Loans for house purchases - outstanding amounts' };
  arr['sp_4_4'] = {
    'pl': 'Kredyty mieszkaniowe - umowy nowe i renegocjowane',
    'en': 'Loans for house purchases - new and renegotiated business',
  };
  arr['sp_5'] = {
    'pl': 'Oprocentowanie kredytów - przedsiębiorstwa',
    'en': 'Interest rates on loans - non-financial corporations',
  };
  arr['sp_5_1'] = { 'pl': 'Kredyty w rachunku bieżącym', 'en': 'Overdrafts' };
  arr['sp_5_2'] = { 'pl': 'Kredyty ogółem  - stany', 'en': 'Total loans - outstanding amounts' };
  arr['sp_5_3'] = {
    'pl': 'Kredyty ogółem  - umowy nowe i renegocjowane',
    'en': 'Total loans - new and renegotiated business',
  };
  arr['sp_6'] = {
    'pl': 'Oprocentowanie kredytów walutowych - przedsiębiorstwa',
    'en': 'Interest rates on foreign currency loans - non-financial corporations',
  };
  arr['sp_6_1'] = {
    'pl': 'Oprocentowanie kredytów przedsiębiorstw w EUR (nowe umowy)',
    'en': 'Interest rates on loans in EUR - new business',
  };
  arr['rf_1'] = {
    'pl': 'Aktywa finansowe i zobowiązania gospodarki polskiej (stany)',
    'en': 'Financial assets and liabilities of Polish economy (stocks)',
  };
  arr['rf_1_1'] = { 'pl': 'Zobowiązania', 'en': 'Liabilities' };
  arr['rf_1_2'] = { 'pl': 'Aktywa finansowe', 'en': 'Financial assets' };
  arr['rf_1_3'] = { 'pl': 'Finansowa wartość netto', 'en': 'Financial net worth' };
  arr['rf_2'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej według sektorów instytucjonalnych (stany)',
    'en': 'Financial assets of Polish economy by institutional sectors (stocks)',
  };
  arr['rf_2_1'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej ogółem',
    'en': 'Total financial assets of Polish economy',
  };
  arr['rf_2_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_2_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_2_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_2_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['rf_2_6'] = { 'pl': 'Aktywa zagraniczne', 'en': 'Foreign assets' };
  arr['rf_3'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej według instrumentów (stany)',
    'en': 'Financial assets of Polish economy by instruments (stocks)',
  };
  arr['rf_3_1'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej ogółem',
    'en': 'Total financial assets of Polish economy',
  };
  arr['rf_3_2'] = { 'pl': 'Złoto monetarne i SDR-y', 'en': 'Monetary gold and SDRs' };
  arr['rf_3_3'] = { 'pl': 'Gotówka i depozyty', 'en': 'Currency and deposits' };
  arr['rf_3_4'] = { 'pl': 'Dłużne papiery wartościowe', 'en': 'Debt securities' };
  arr['rf_3_5'] = { 'pl': 'Kredyty i pożyczki', 'en': 'Loans' };
  arr['rf_3_6'] = {
    'pl': 'Udziały kapitałowe i udziały lub jednostki w funduszach inwestycyjnych',
    'en': 'Equity and investment fund shares or units',
  };
  arr['rf_3_7'] = {
    'pl': 'Systemy ubezpieczeniowe, emerytalno-rentowe i standaryzowanych gwarancji',
    'en': 'Insurance, pension and standardised guarantee schemes',
  };
  arr['rf_3_8'] = {
    'pl': 'Instrumenty pochodne i opcje na akcje dla pracowników',
    'en': 'Financial derivatives and employee stock options',
  };
  arr['rf_3_9'] = { 'pl': 'Pozostałe kwoty do otrzymania', 'en': 'Other accounts receivable' };
  arr['rf_4'] = {
    'pl': 'Zobowiązania gospodarki polskiej według sektorów instytucjonalnych (stany)',
    'en': 'Liabilities of Polish economy by institutional sectors (stocks)',
  };
  arr['rf_4_1'] = { 'pl': 'Zobowiązania gospodarki polskiej ogółem', 'en': 'Total liabilities of Polish economy' };
  arr['rf_4_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_4_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_4_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_4_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['rf_4_6'] = { 'pl': 'Zobowiązania wobec nierezydentów', 'en': 'Liabilities to non-residents' };
  arr['rf_5'] = {
    'pl': 'Zobowiązania gospodarki polskiej według instrumentów (stany)',
    'en': 'Liabilities of Polish economy by instruments (stocks)',
  };
  arr['rf_5_1'] = { 'pl': 'Zobowiązania gospodarki polskiej ogółem', 'en': 'Total liabilities of Polish economy' };
  arr['rf_5_2'] = { 'pl': 'SDR-y', 'en': 'SDRs' };
  arr['rf_5_3'] = { 'pl': 'Gotówka i depozyty', 'en': 'Currency and deposits' };
  arr['rf_5_4'] = { 'pl': 'Dłużne papiery wartościowe', 'en': 'Debt securities' };
  arr['rf_5_5'] = { 'pl': 'Kredyty i pożyczki', 'en': 'Loans' };
  arr['rf_5_6'] = {
    'pl': 'Udziały kapitałowe i udziały lub jednostki w funduszach inwestycyjnych',
    'en': 'Equity and investment fund shares or units',
  };
  arr['rf_5_7'] = {
    'pl': 'Systemy ubezpieczeniowe, emerytalno-rentowe i standaryzowanych gwarancji',
    'en': 'Insurance, pension and standardised guarantee schemes',
  };
  arr['rf_5_8'] = {
    'pl': 'Instrumenty pochodne i opcje na akcje dla pracowników',
    'en': 'Financial derivatives and employee stock options',
  };
  arr['rf_5_9'] = { 'pl': 'Pozostałe kwoty do zapłacenia', 'en': 'Other accounts payable' };
  arr['rf_6'] = {
    'pl': 'Finansowa wartość netto - według sektorów instytucjonalnych (stany)',
    'en': 'Financial net worth - by institutional sectors',
  };
  arr['rf_6_1'] = { 'pl': 'Gospodarka polska ogółem', 'en': 'Total Polish economy' };
  arr['rf_6_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_6_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_6_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_6_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['rf_7'] = {
    'pl': 'Aktywa finansowe i zobowiązania gospodarki polskiej (transakcje)',
    'en': 'Financial assets and liabilities of Polish economy (transactions)',
  };
  arr['rf_7_1'] = { 'pl': 'Aktywa finansowe', 'en': 'Financial assets' };
  arr['rf_7_2'] = { 'pl': 'Zobowiązania', 'en': 'Liabilities' };
  arr['rf_7_3'] = { 'pl': 'Wierzytelności netto / zadłużenie netto', 'en': 'Net lending / net borrowing' };
  arr['rf_8'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej według sektorów instytucjonalnych (transakcje)',
    'en': 'Financial assets of Polish economy by institutional sectors (transactions)',
  };
  arr['rf_8_1'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej ogółem',
    'en': 'Total financial assets of Polish economy',
  };
  arr['rf_8_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_8_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_8_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_8_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['rf_8_6'] = { 'pl': 'Aktywa zagraniczne', 'en': 'Foreign assets' };
  arr['rf_9'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej według instrumentów (transakcje)',
    'en': 'Financial assets of Polish economy by instruments (transactions)',
  };
  arr['rf_9_1'] = {
    'pl': 'Aktywa finansowe gospodarki polskiej ogółem',
    'en': 'Total financial assets of Polish economy',
  };
  arr['rf_9_2'] = { 'pl': 'Złoto monetarne i SDR-y', 'en': 'Monetary gold and SDRs' };
  arr['rf_9_3'] = { 'pl': 'Gotówka i depozyty', 'en': 'Currency and deposits' };
  arr['rf_9_4'] = { 'pl': 'Dłużne papiery wartościowe', 'en': 'Debt securities' };
  arr['rf_9_5'] = { 'pl': 'Kredyty i pożyczki', 'en': 'Loans' };
  arr['rf_9_6'] = {
    'pl': 'Udziały kapitałowe i udziały lub jednostki w funduszach inwestycyjnych',
    'en': 'Equity and investment fund shares or units',
  };
  arr['rf_9_7'] = {
    'pl': 'Systemy ubezpieczeniowe, emerytalno-rentowe i standaryzowanych gwarancji',
    'en': 'Insurance, pension and standardised guarantee schemes',
  };
  arr['rf_9_8'] = {
    'pl': 'Instrumenty pochodne i opcje na akcje dla pracowników',
    'en': 'Financial derivatives and employee stock options',
  };
  arr['rf_9_9'] = { 'pl': 'Pozostałe kwoty do otrzymania', 'en': 'Other accounts receivable' };
  arr['rf_10'] = {
    'pl': 'Zobowiązania gospodarki polskiej według sektorów instytucjonalnych (transakcje)',
    'en': 'Liabilities of Polish economy by institutional sectors (transactions)',
  };
  arr['rf_10_1'] = { 'pl': 'Zobowiązania gospodarki polskiej ogółem', 'en': 'Total liabilities of Polish economy' };
  arr['rf_10_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_10_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_10_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_10_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['rf_10_6'] = { 'pl': 'Zobowiązania wobec nierezydentów', 'en': 'Liabilities to non-residents' };
  arr['rf_11'] = {
    'pl': 'Zobowiązania gospodarki polskiej według instrumentów (transakcje)',
    'en': 'Liabilities of Polish economy by instruments (transactions)',
  };
  arr['rf_11_1'] = { 'pl': 'Zobowiązania gospodarki polskiej ogółem', 'en': 'Total liabilities of Polish economy' };
  arr['rf_11_2'] = { 'pl': 'SDR-y', 'en': 'SDRs' };
  arr['rf_11_3'] = { 'pl': 'Gotówka i depozyty', 'en': 'Currency and deposits' };
  arr['rf_11_4'] = { 'pl': 'Dłużne papiery wartościowe', 'en': 'Debt securities' };
  arr['rf_11_5'] = { 'pl': 'Kredyty i pożyczki', 'en': 'Loans' };
  arr['rf_11_6'] = {
    'pl': 'Udziały kapitałowe i udziały lub jednostki w funduszach inwestycyjnych',
    'en': 'Equity and investment fund shares or units',
  };
  arr['rf_11_7'] = {
    'pl': 'Systemy ubezpieczeniowe, emerytalno-rentowe i standaryzowanych gwarancji',
    'en': 'Insurance, pension and standardised guarantee schemes',
  };
  arr['rf_11_8'] = {
    'pl': 'Instrumenty pochodne i opcje na akcje dla pracowników',
    'en': 'Financial derivatives and employee stock options',
  };
  arr['rf_11_9'] = { 'pl': 'Pozostałe kwoty do zapłacenia', 'en': 'Other accounts receivable' };
  arr['rf_12'] = {
    'pl': 'Wierzytelności netto / zadłużenie netto - według sektorów instytucjonalnych (transakcje)',
    'en': 'Net lending / net borrowing - by institutional sectors (transactions)',
  };
  arr['rf_12_1'] = { 'pl': 'Gospodarka polska ogółem', 'en': 'Total economy' };
  arr['rf_12_2'] = { 'pl': 'Przedsiębiorstwa niefinansowe', 'en': 'Non-financial corporations' };
  arr['rf_12_3'] = { 'pl': 'Instytucje finansowe', 'en': 'Financial corporations' };
  arr['rf_12_4'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['rf_12_5'] = { 'pl': 'Gospodarstwa domowe i INKgd', 'en': 'Households and NPISHs' };
  arr['mon_1'] = { 'pl': 'Agregaty monetarne (stany)', 'en': 'Monetary aggregates (outstanding amounts)' };
  arr['mon_1_1'] = { 'pl': 'Pieniądz M0', 'en': 'M0' };
  arr['mon_1_2'] = { 'pl': 'Pieniądz M1', 'en': 'M1' };
  arr['mon_1_3'] = { 'pl': 'Pieniądz M3', 'en': 'M3' };
  arr['mon_2'] = {
    'pl': 'Depozyty i inne zobowiązania bieżące MIF wobec gospodarstw domowych i przedsiębiorstw (stany)',
    'en': 'Deposits and other liabilities to households and non-financial corporations (outstanding amounts)',
  };
  arr['mon_2_1'] = { 'pl': 'Gospodarstwa domowe', 'en': 'Households' };
  arr['mon_2_2'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Non-financial corporations' };
  arr['mon_3'] = {
    'pl': 'Depozyty i inne zobowiązania do 2 lat włącznie MIF wobec gospodarstw domowych i przedsiębiorstw (stany)',
    'en': 'Deposits and other liabilities with agreed maturity up to 2 years to households and non-financial corporations (outstanding amounts)',
  };
  arr['mon_3_1'] = { 'pl': 'Gospodarstwa domowe', 'en': 'Households' };
  arr['mon_3_2'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Non-financial corporations' };
  arr['mon_4'] = {
    'pl': 'Główne czynniki kreacji pieniądza M3 (stany)',
    'en': 'Counterparts to M3 (outstanding amounts)',
  };
  arr['mon_4_1'] = {
    'pl': 'Należności od sektorów krajowych innych niż MIF i instytucje rządowe szczebla centralnego',
    'en': 'Credit to domestic residents (other than MFIs and central government)',
  };
  arr['mon_4_2'] = { 'pl': 'Aktywa zagraniczne netto', 'en': 'External assets, net' };
  arr['mon_4_3'] = {
    'pl': 'Zadłużenie netto instytucji rządowych szczebla centralnego',
    'en': 'Credit to central government, net',
  };
  arr['mon_5'] = {
    'pl': 'Kredyty i inne należności od gospodarstw domowych i przedsiębiorstw (stany)',
    'en': 'Loans and other liabilities of households and non-financial corporations (outstanding amounts)',
  };
  arr['mon_5_1'] = { 'pl': 'Gospodarstwa', 'en': 'Households' };
  arr['mon_5_2'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Non-financial corporations' };
  arr['mon_6'] = {
    'pl': 'Kredyty i pożyczki mieszkaniowe udzielone gospodarstwom domowym (stany)',
    'en': 'Loans for houses (outstanding amounts)',
  };
  arr['mon_6_1'] = { 'pl': 'Złotowe', 'en': 'Domestic currency' };
  arr['mon_6_2'] = { 'pl': 'Walutowe', 'en': 'Foreign currencies' };
  arr['mon_6_3'] = { 'pl': 'Ogółem', 'en': 'All currencies' };
  arr['mon_7'] = { 'pl': 'Pieniądz M3 (zmiany)', 'en': 'M3 (changes)' };
  arr['mon_7_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_7_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_7_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_8'] = { 'pl': 'Pieniądz M1 (zmiany)', 'en': 'M1 (changes)' };
  arr['mon_8_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_8_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_8_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_9'] = {
    'pl': 'Pieniądz gotówkowy w obiegu (poza kasami banków) (zmiany)',
    'en': 'Currency in circulation (excluding vault cash) (changes)',
  };
  arr['mon_9_1'] = { 'pl': 'Zmiany 1-miesięczne', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_9_2'] = { 'pl': 'Zmiany 12-miesięczne', 'en': 'Annual growth rates (%)' };
  arr['mon_10'] = {
    'pl': 'Depozyty i inne zobowiązania bieżące MIF wobec gospodarstw domowych (zmiany)',
    'en': 'Overnight deposits and other liabilities to households (changes)',
  };
  arr['mon_10_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_10_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_10_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_11'] = {
    'pl': 'Depozyty i inne zobowiązania bieżące MIF wobec przedsiębiorstw (zmiany)',
    'en': 'Overnight deposits and other liabilities to non-financial corporations (changes)',
  };
  arr['mon_11_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_11_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_11_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_12'] = {
    'pl': 'Depozyty i inne zobow. do 2 lat włącznie oraz depozyty z terminem wypowiedzenia do 3 miesięcy włącznie MIF wobec gospodarstw domowych (zmiany)',
    'en': 'Deposits with agreed maturity up to 2 years and deposits redeemable at notice up to 3 months of households (changes)',
  };
  arr['mon_12_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_12_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_12_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_13'] = {
    'pl': 'Depozyty i inne zobow. do 2 lat włącznie oraz depozyty z terminem wypowiedzenia do 3 miesięcy włącznie MIF wobec przedsiębiorstw (zmiany)',
    'en': 'Deposits with agreed maturity up to 2 years and deposits redeemable at notice up to 3 months of non-financial corporations (changes)',
  };
  arr['mon_13_1'] = { 'pl': 'Zmiany 1-miesięczne surowe (mln zł)', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_13_2'] = { 'pl': 'Zmiany 12-miesięczne surowe (%)', 'en': 'Annual growth rates (%)' };
  arr['mon_13_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne (%)',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_14'] = {
    'pl': 'Należności od sektorów krajowych innych niż MIF i instytucje rządowe szczebla centralnego (zmiany)',
    'en': 'Credit to domestic residents other than MFIs and central government (changes)',
  };
  arr['mon_14_1'] = { 'pl': 'Zmiany 1-miesięczne surowe', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_14_2'] = { 'pl': 'Zmiany 12-miesięczne surowe', 'en': 'Annual growth rates (%)' };
  arr['mon_14_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_15'] = { 'pl': 'Aktywa zagraniczne netto (zmiany)', 'en': 'External assets, net (changes)' };
  arr['mon_15_1'] = { 'pl': 'Zmiany 1-miesięczne surowe', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_15_2'] = { 'pl': 'Zmiany 12-miesięczne surowe', 'en': 'Annual growth rates (%)' };
  arr['mon_15_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_16'] = {
    'pl': 'Zadłużenie netto instytucji rządowych szczebla centralnego (zmiany)',
    'en': 'Credit to central government, net (changes)',
  };
  arr['mon_16_1'] = { 'pl': 'Zmiany 1-miesięczne surowe', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_16_2'] = { 'pl': 'Zmiany 12-miesięczne surowe', 'en': 'Annual growth rates (%)' };
  arr['mon_16_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_17'] = {
    'pl': 'Kredyty i inne należności od gospodarstw domowych (zmiany)',
    'en': 'Loans and other claims on households (changes)',
  };
  arr['mon_17_1'] = { 'pl': 'Zmiany 1-miesięczne surowe', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_17_2'] = { 'pl': 'Zmiany 12-miesięczne surowe', 'en': 'Annual growth rates (%)' };
  arr['mon_17_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['mon_18'] = {
    'pl': 'Kredyty i inne należności od przedsiębiorstw (zmiany)',
    'en': 'Loans and other claims on non-financial corporations (changes)',
  };
  arr['mon_18_1'] = { 'pl': 'Zmiany 1-miesięczne surowe', 'en': 'Monthly changes (PLN millions)' };
  arr['mon_18_2'] = { 'pl': 'Zmiany 12-miesięczne surowe', 'en': 'Annual growth rates (%)' };
  arr['mon_18_3'] = {
    'pl': 'Zmiany 12-miesięczne transakcyjne',
    'en': 'Annual growth rates, adjusted for non-transactions (%)',
  };
  arr['fi_1'] = {
    'pl': 'Liczebność towarzystw funduszy inwestycyjnych, funduszy inwestycyjnych i subfunduszy',
    'en': 'Number of investment fund management companies, investment funds and sub-funds',
  };
  arr['fi_1_1'] = {
    'pl': 'Towarzystwa funduszy inwestycyjnych ogółem',
    'en': 'Investment fund management companies, total',
  };
  arr['fi_1_2'] = { 'pl': 'Fundusze otwarte', 'en': 'Open-end funds' };
  arr['fi_1_3'] = { 'pl': 'Specjalistyczne fundusze otwarte', 'en': 'Specialised open-end funds' };
  arr['fi_1_4'] = { 'pl': 'Fundusze zamknięte', 'en': 'Closed-end funds' };
  arr['fi_1_5'] = { 'pl': 'Subfundusze', 'en': 'Sub-funds' };
  arr['fi_2'] = { 'pl': 'Aktywa netto funduszy inwestycyjnych ', 'en': 'Net assets value of investment funds' };
  arr['fi_2_1'] = { 'pl': 'Fundusze otwarte', 'en': 'Open-end funds' };
  arr['fi_2_2'] = { 'pl': 'Specjalistyczne fundusze otwarte', 'en': 'Specialised open-end funds' };
  arr['fi_2_3'] = { 'pl': 'Fundusze zamknięte', 'en': 'Closed-end funds' };
  arr['fi_3'] = {
    'pl': 'Aktywa netto funduszy inwestycyjnych według kryterium polityki inwestycyjnej funduszu ',
    'en': 'Net assets value of investment funds broken down by investment policy',
  };
  arr['fi_3_1'] = { 'pl': 'FI akcyjne', 'en': 'Equity IF' };
  arr['fi_3_2'] = { 'pl': 'FI dłużnych papierów wartościowych', 'en': 'Bond IF' };
  arr['fi_3_3'] = { 'pl': 'FI zrównoważone', 'en': 'Balanced IF' };
  arr['fi_3_4'] = { 'pl': 'FI stabilnego wzrostu', 'en': 'Stable growth IF' };
  arr['fi_3_5'] = { 'pl': 'FI pozostałe', 'en': 'Other IF' };
  arr['fi_4'] = {
    'pl': 'Aktywa i zobowiązania funduszy inwestycyjnych ogółem',
    'en': 'Total assets and liabilities of investment funds',
  };
  arr['fi_4_1'] = { 'pl': 'Aktywa ogółem ', 'en': 'Total assets' };
  arr['fi_4_2'] = { 'pl': 'Dłużne papiery wartościowe ', 'en': 'Debt securities' };
  arr['fi_4_3'] = { 'pl': 'Udziałowe instrumenty finansowe ', 'en': 'Equity' };
  arr['fi_4_4'] = { 'pl': 'Depozyty i pożyczki udzielone ', 'en': 'Deposits' };
  arr['fi_4_5'] = { 'pl': 'Instrumenty pochodne', 'en': 'Derivatives' };
  arr['fi_4_6'] = { 'pl': 'Pozostałe aktywa', 'en': 'Remaining assets' };
  arr['fi_4_7'] = { 'pl': 'Zobowiązania ', 'en': 'Liabilities' };
  arr['fi_4_8'] = { 'pl': 'Aktywa netto', 'en': 'Net assets' };
  arr['fi_5'] = {
    'pl': 'Zbycie i umorzenie jednostek uczestnictwa i certyfikatów inwestycyjnych',
    'en': 'Sales and redemptions of investment funds\' units/investment certificates',
  };
  arr['fi_5_1'] = { 'pl': 'JU zbycie', 'en': 'Units sales' };
  arr['fi_5_2'] = { 'pl': 'JU umorzenie', 'en': 'Units redemptions' };
  arr['fi_5_3'] = { 'pl': 'CI zbycie', 'en': 'Investment certificates sales' };
  arr['fi_5_4'] = { 'pl': 'CI umorzenie', 'en': 'Investment certificates redemptions' };
  arr['fi_6'] = {
    'pl': 'Zbycie jednostek uczestnictwa i certyfikatów inwestycyjnych funduszy inwestycyjnych według kryterium polityki inwestycyjnej funduszu ',
    'en': 'Sales of investment funds\' units/investment certificates broken down by investment policy',
  };
  arr['fi_6_1'] = { 'pl': 'FI akcyjne', 'en': 'Equity IF' };
  arr['fi_6_2'] = { 'pl': 'FI dłużnych papierów wartościowych', 'en': 'Bond IF' };
  arr['fi_6_3'] = { 'pl': 'FI zrównoważone', 'en': 'Balanced IF' };
  arr['fi_6_4'] = { 'pl': 'FI stabilnego wzrostu', 'en': 'Stable growth IF' };
  arr['fi_6_5'] = { 'pl': 'FI pozostałe', 'en': 'Other IF' };
  arr['fi_7'] = {
    'pl': 'Umorzenie jednostek uczestnictwa i certyfikatów inwestycyjnych funduszy inwestycyjnych według kryterium polityki inwestycyjnej funduszu ',
    'en': 'Redemptions of investment funds\' units/investment certificates broken down by investment policy',
  };
  arr['fi_7_1'] = { 'pl': 'FI akcyjne ', 'en': 'Equity IF' };
  arr['fi_7_2'] = { 'pl': 'FI dłużnych papierów wartościowych ', 'en': 'Bond IF' };
  arr['fi_7_3'] = { 'pl': 'FI zrównoważone ', 'en': 'Balanced IF' };
  arr['fi_7_4'] = { 'pl': 'FI stabilnego wzrostu ', 'en': 'Stable growth IF' };
  arr['fi_7_5'] = { 'pl': 'FI pozostałe ', 'en': 'Other IF' };
  arr['fi_8'] = {
    'pl': 'Jednostki uczestnictwa funduszy inwestycyjnych w posiadaniu inwestorów według sektora inwestora ',
    'en': 'Investment funds\' units broken down by investors\' sector',
  };
  arr['fi_8_1'] = { 'pl': 'Monetarne instytucje finansowe ', 'en': 'Monetary financial institutions' };
  arr['fi_8_2'] = { 'pl': 'Instytucje rządowe i samorządowe', 'en': 'General government' };
  arr['fi_8_3'] = {
    'pl': 'Fun. inw. i inne poz. inst. pośrednictwa fin. oraz pomocnicze inst. fin.',
    'en': 'Investment funds, other financial intermediaries, financial auxiliaries, captive financial institutions and money lenders',
  };
  arr['fi_8_4'] = {
    'pl': 'Inst. ubezp., reasekuracji i fund. emerytalne ',
    'en': 'Insurance corporations and pension funds',
  };
  arr['fi_8_5'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Non-financial corporations' };
  arr['fi_8_6'] = {
    'pl': 'Gosp. dom. i instytucje niekomercyjne działające na rzecz gosp.  dom.',
    'en': 'Households and non-profit institutions serving households',
  };
  arr['fi_9'] = {
    'pl': 'Certyfikaty inwestycyjne funduszy inwestycyjnych w posiadaniu inwestorów według sektora inwestora',
    'en': 'Investment funds\' investment certificates broken down by investors\' sector',
  };
  arr['fi_9_1'] = {
    'pl': 'Fun. inw. i inne poz. inst. pośrednictwa fin. oraz pomocnicze inst. fin.',
    'en': 'Investment funds, other financial intermediaries, financial auxiliaries, captive financial institutions and money lenders',
  };
  arr['fi_9_2'] = {
    'pl': 'Inst. ubezp., reasekuracji i fund. emerytalne ',
    'en': 'Insurance corporations and pension funds',
  };
  arr['fi_9_3'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Non-financial corporations' };
  arr['fi_9_4'] = {
    'pl': 'Gosp. dom. i instytucje niekomercyjne działające na rzecz gosp.  dom.',
    'en': 'Households and non-profit institutions serving households',
  };
  arr['so_1'] = { 'pl': 'Suma bilansowa sektora bankowego', 'en': 'Total assets of  banking sector' };
  arr['so_1_1'] = { 'pl': 'Suma bilansowa', 'en': 'Total assets ' };
  arr['so_2'] = {
    'pl': 'Należności brutto sektora bankowego',
    'en': 'Banking sector loans and advances - gross carrying amount',
  };
  arr['so_2_1'] = { 'pl': 'Sektor finansowy', 'en': 'Financial sector' };
  arr['so_2_2'] = { 'pl': 'Sektor niefinansowy', 'en': 'Non-financial sector' };
  arr['so_2_3'] = { 'pl': 'Sektor samorządowy', 'en': 'Central and local government sector' };
  arr['so_3'] = {
    'pl': 'Należności od przedsiębiorstw według kryterium jakości',
    'en': 'Loans and advances on corporations, by quality',
  };
  arr['so_3_1'] = { 'pl': 'Bez utraty wartości', 'en': 'Unimpaired' };
  arr['so_3_2'] = { 'pl': 'Z utratą wartości', 'en': 'Impaired' };
  arr['so_4'] = {
    'pl': 'Należności od przedsiębiorstw według rodzaju',
    'en': 'Loans and advances on corporations, by type',
  };
  arr['so_4_1'] = { 'pl': 'Kredyty operacyjne', 'en': 'Operational loans' };
  arr['so_4_2'] = { 'pl': 'Kredyty inwestycyjne', 'en': 'Investment loans' };
  arr['so_4_3'] = { 'pl': 'Kredyty na nieruchomości', 'en': 'Real estate loans' };
  arr['so_4_4'] = { 'pl': 'Inne należności', 'en': 'Other advances' };
  arr['so_5'] = {
    'pl': 'Należności od gospodarstw domowych według kryterium jakości',
    'en': 'Loans and advances on households, by quality',
  };
  arr['so_5_1'] = { 'pl': 'Bez utraty wartości', 'en': 'Unimpaired' };
  arr['so_5_2'] = { 'pl': 'Z utratą wartości', 'en': 'Impaired' };
  arr['so_6'] = {
    'pl': 'Należności od gospodarstw domowych według rodzaju',
    'en': 'Loans and advances on households, by type',
  };
  arr['so_6_1'] = {
    'pl': 'Kredyty operacyjne i w rachunku karty kredytowej',
    'en': 'Operational and credit card loans',
  };
  arr['so_6_2'] = { 'pl': 'Kredyty konsumpcyjne', 'en': 'Consumer loans' };
  arr['so_6_3'] = { 'pl': 'Kredyty inwestycyjne', 'en': 'Investment loans' };
  arr['so_6_4'] = { 'pl': 'Kredyty na nieruchomości', 'en': 'Real estate loans' };
  arr['so_6_5'] = { 'pl': 'Inne należności', 'en': 'Other advances' };
  arr['so_7'] = { 'pl': 'Kredyty na nieruchomości', 'en': 'Real estate loans' };
  arr['so_7_1'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Corporations' };
  arr['so_7_2'] = { 'pl': 'Gospodarstwa domowe', 'en': 'Households' };
  arr['so_7v2'] = { 'pl': 'Kredyty na nieruchomości (struktura walutowa)', 'en': 'Real estate loans, by currency' };
  arr['so_7v2_1'] = { 'pl': 'Przedsiębiorstwa, PLN', 'en': 'Corporations, PLN' };
  arr['so_7v2_2'] = { 'pl': 'Przedsiębiorstwa, waluty obce', 'en': 'Corporations, foreign currency' };
  arr['so_7v2_3'] = { 'pl': 'Gospodarstwa domowe, PLN', 'en': 'Households, PLN' };
  arr['so_7v2_4'] = { 'pl': 'Gospodarstwa domowe, CHF', 'en': 'Households, CHF' };
  arr['so_7v2_5'] = {
    'pl': 'Gospodarstwa domowe, pozostałe waluty obce',
    'en': 'Households, other foreign currencies',
  };
  arr['so_8'] = { 'pl': 'Należności zabezpieczone hipoteką', 'en': 'Loans and advances collateralised with mortgage' };
  arr['so_8_1'] = { 'pl': 'Przedsiębiorstwa', 'en': 'Corporations' };
  arr['so_8_2'] = { 'pl': 'Gospodarstwa domowe', 'en': 'Households' };
  arr['so_8v2'] = {
    'pl': 'Należności zabezpieczone hipoteką (struktura walutowa)',
    'en': 'Loans and advances collateralised with mortgage, by currency',
  };
  arr['so_8v2_1'] = { 'pl': 'Przedsiębiorstwa, PLN', 'en': 'Corporations, PLN' };
  arr['so_8v2_2'] = { 'pl': 'Przedsiębiorstwa, waluty obce', 'en': 'Corporations, foreign currency' };
  arr['so_8v2_3'] = { 'pl': 'Gospodarstwa domowe, PLN', 'en': 'Households, PLN' };
  arr['so_8v2_4'] = { 'pl': 'Gospodarstwa domowe, CHF', 'en': 'Households, CHF' };
  arr['so_8v2_5'] = {
    'pl': 'Gospodarstwa domowe, pozostałe waluty obce',
    'en': 'Households, other foreign currencies',
  };
  arr['so_9'] = { 'pl': 'Zobowiązania sektora bankowego', 'en': 'Banking sector liabilities' };
  arr['so_9_1'] = { 'pl': 'Sektor finansowy', 'en': 'Financial sector' };
  arr['so_9_2'] = { 'pl': 'Sektor niefinansowy', 'en': 'Non-financial sector' };
  arr['so_9_3'] = { 'pl': 'Sektor samorządowy', 'en': 'Central and local government sector' };
  arr['so_10'] = { 'pl': 'Depozyty sektora niefinansowego', 'en': 'Deposits of non-financial sector' };
  arr['so_10_1'] = { 'pl': 'Osoby prywatne', 'en': 'Individuals' };
  arr['so_10_2'] = {
    'pl': 'Sektor niefinansowy bez osób prywatnych',
    'en': 'Non-financial sector without individuals',
  };
  arr['so_10v2'] = {
    'pl': 'Depozyty sektora niefinansowego (struktura terminowa)',
    'en': 'Deposits of non-financial sector, by term',
  };
  arr['so_10v2_1'] = { 'pl': 'Osoby prywatne, bieżące', 'en': 'Individuals, current' };
  arr['so_10v2_2'] = { 'pl': 'Osoby prywatne, terminowe', 'en': 'Individuals, term' };
  arr['so_10v2_3'] = {
    'pl': 'Pozostały sektor niefinansowy bez osób prywatnych, bieżące',
    'en': 'Non-financial sector without individuals, current',
  };
  arr['so_10v2_4'] = {
    'pl': 'Pozostały sektor niefinansowy bez osób prywatnych, terminowe',
    'en': 'Non-financial sector without individuals, term',
  };
  arr['so_11'] = { 'pl': 'Przychody i koszty z tytułu odsetek', 'en': 'Interest income and expenses' };
  arr['so_11_1'] = { 'pl': 'Przychody z tytułu odsetek', 'en': 'Interest income' };
  arr['so_11_2'] = { 'pl': 'Koszty z tytułu odsetek', 'en': 'Interest expenses' };
  arr['so_12'] = {
    'pl': 'Przychody i koszty z tytułu opłat i prowizji',
    'en': 'Fee and commission income and expenses',
  };
  arr['so_12_1'] = { 'pl': 'Przychody z tytułu opłat i prowizji', 'en': 'Fee and commission income' };
  arr['so_12_2'] = { 'pl': 'Koszty z tytułu opłat i prowizji', 'en': 'Fee and commission expenses' };
  arr['bp_1'] = { 'pl': 'Rachunek bieżący i kapitałowy ', 'en': 'Current and capital account' };
  arr['bp_1_1'] = { 'pl': 'Rachunek bieżący', 'en': 'Current account' };
  arr['bp_1_2'] = { 'pl': 'Saldo obrotów towarowych', 'en': 'Balance on goods' };
  arr['bp_1_3'] = { 'pl': 'Saldo usług', 'en': 'Balance on services' };
  arr['bp_1_4'] = { 'pl': 'Saldo dochodów pierwotnych', 'en': 'Balance on primary income' };
  arr['bp_1_5'] = { 'pl': 'Saldo dochodów wtórnych', 'en': 'Balance on secondary income' };
  arr['bp_1_6'] = { 'pl': 'Rachunek kapitałowy', 'en': 'Capital account' };
  arr['bp_1_7'] = { 'pl': 'Rachunek bieżący i kapitałowy', 'en': 'Current and capital account' };
  arr['bp_2'] = {
    'pl': 'Saldo rachunku bieżącego i kapitałowego w % PKB',
    'en': 'Current and capital account in % GDP',
  };
  arr['bp_2_1'] = { 'pl': 'Rachunek bieżący', 'en': 'Current account' };
  arr['bp_2_2'] = { 'pl': 'Rachunek kapitałowy', 'en': 'Capital account' };
  arr['bp_2_3'] = { 'pl': 'Rachunek bieżący i kapitałowy', 'en': 'Current and capital account' };
  arr['bp_3'] = { 'pl': 'Towary i usługi', 'en': 'Goods and services' };
  arr['bp_3_1'] = {
    'pl': 'Saldo obrotów towarowych i usługowych (prawa oś)',
    'en': 'Balance on goods and services (right axis)',
  };
  arr['bp_3_2'] = { 'pl': 'Eksport', 'en': 'Exports' };
  arr['bp_3_3'] = { 'pl': 'Import', 'en': 'Imports' };
  arr['bp_4'] = {
    'pl': 'Dynamika eksportu ogółem (r/r) - udział we wzroście wartości głównych krajów i grup krajów',
    'en': 'Total exports dynamics (y/y) - growth share of main countries and unions',
  };
  arr['bp_4_1'] = { 'pl': 'Ogółem', 'en': 'Total' };
  arr['bp_4_2'] = { 'pl': 'Niemcy', 'en': 'Germany' };
  arr['bp_4_3'] = { 'pl': 'Pozostałe kraje strefy euro', 'en': 'Other EA countries' };
  arr['bp_4_4'] = { 'pl': 'Pozostałe kraje UE', 'en': 'Other EU countries' };
  arr['bp_4_5'] = { 'pl': 'Pozostałe kraje europejskie', 'en': 'Other European countries' };
  arr['bp_4_6'] = { 'pl': 'Kraje pozaeuropejskie', 'en': 'Non-European countries' };
  arr['bp_5'] = {
    'pl': 'Dynamika importu ogółem (r/r) - udział we wzroście wartości importu głównych grup towarów',
    'en': 'Total imports dynamics (y/y) - growth share of main countries and unions',
  };
  arr['bp_5_1'] = { 'pl': 'Ogółem', 'en': 'Total' };
  arr['bp_5_2'] = { 'pl': 'Surowce i paliwa', 'en': 'Crude materials and fuels' };
  arr['bp_5_3'] = { 'pl': 'Dobra pośrednie', 'en': 'Intermediate goods' };
  arr['bp_5_4'] = { 'pl': 'Dobra inwestycyjne', 'en': 'Investment goods' };
  arr['bp_5_5'] = { 'pl': 'Dobra konsumpcyjne', 'en': 'Consumption goods' };
  arr['bp_5_6'] = { 'pl': 'Samochody osobowe', 'en': 'Passenger cars' };
  arr['bp_6'] = { 'pl': 'Międzynarodowy handel usługami', 'en': 'International trade in services ' };
  arr['bp_6_1'] = { 'pl': 'Saldo usług', 'en': 'Balance on services' };
  arr['bp_6_2'] = { 'pl': 'Transport', 'en': 'Transport' };
  arr['bp_6_3'] = { 'pl': 'Podróże zagraniczne   ', 'en': 'Travel' };
  arr['bp_6_4'] = { 'pl': 'Uszlachetnianie', 'en': 'Manufacturing services' };
  arr['bp_6_5'] = { 'pl': 'Naprawy', 'en': 'Maintenance and repair services' };
  arr['bp_6_6'] = { 'pl': 'Pozostałe', 'en': 'Other' };
  arr['bp_7'] = { 'pl': 'Inwestycje bezpośrednie - pasywa', 'en': 'Direct investment - liabilities' };
  arr['bp_7_1'] = { 'pl': 'Inwestycje bezpośrednie - pasywa', 'en': 'Direct investment - liabilities' };
  arr['bp_7_2'] = { 'pl': 'Akcje i udziały', 'en': 'Equity and investment fund shares' };
  arr['bp_7_3'] = { 'pl': 'Reinwestycje zysków', 'en': 'Reinvestment of earnings' };
  arr['bp_7_4'] = { 'pl': 'Instrumenty dłużne', 'en': 'Debt intruments' };
  arr['bp_8'] = { 'pl': 'Pozostałe inwestycje – pasywa', 'en': 'Other investments - liabilities' };
  arr['bp_8_1'] = { 'pl': 'Pozostałe inwestycje - pasywa', 'en': 'Other investments - liabilities' };
  arr['bp_8_2'] = { 'pl': 'Narodowy Bank Polski', 'en': 'Monetary authorities' };
  arr['bp_8_3'] = { 'pl': 'Sektor rządowy', 'en': 'General government' };
  arr['bp_8_4'] = { 'pl': 'Monetarne instytucje finansowe', 'en': 'MFIs' };
  arr['bp_8_5'] = { 'pl': 'Pozostałe sektory', 'en': 'Other sectors' };
  arr['bp_9'] = { 'pl': 'Stan oficjalnych aktywów rezerwowych', 'en': 'Official reserve assets stock' };
  arr['bp_9_1'] = { 'pl': 'Oficjalne aktywa rezerwowe', 'en': 'Official reserve assets' };
  arr['bp_9_2'] = { 'pl': 'Złoto monetarne', 'en': 'Monetary gold' };
  arr['bp_9_3'] = { 'pl': 'SDR', 'en': 'SDR' };
  arr['bp_9_4'] = { 'pl': 'Pozycja rezerwowa w MFW', 'en': 'Reserve position in the IMF' };
  arr['bp_9_5'] = { 'pl': 'Należności w walutach wymienialnych', 'en': 'Other reserve assets' };
};

export { setArrNames };

const setArrLang = () => {
  let arrLang = [];
  arrLang['Back'] = { 'pl': 'Powrót', 'en': 'Back' };
  arrLang['Zoom'] = { 'pl': 'Powiększenie', 'en': 'Zoom' };
  arrLang['MonthShort'] = {
    'pl': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    'en': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  };
  arrLang['MonthFull'] = {
    'pl': ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  };
  arrLang['DayFull'] = {
    'pl': ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    'en': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  };
  arrLang['All'] = { 'pl': 'Wszystko', 'en': 'All' };
  arrLang['Quarters'] = { 'pl': 'kwartałów', 'en': 'quarters' };
  arrLang['Quarter'] = { 'pl': 'kwartał', 'en': 'quarter' };
  arrLang['Year'] = { 'pl': 'rok', 'en': 'year' };
  arrLang['Months'] = { 'pl': 'miesięcy', 'en': 'months' };
  arrLang['Years'] = { 'pl': 'lat', 'en': 'years' };
  arrLang['Source'] = { 'pl': 'Źródło', 'en': 'Source' };
  arrLang['LastUpdate'] = { 'pl': 'Ostatnia aktualizacja danych ', 'en': 'Last update of data ' };
  arrLang['CurrBreak'] = { 'pl': 'Pokaż strukturę walutową', 'en': 'Show currency breakdown' };
  arrLang['TermBreak'] = { 'pl': 'Pokaż strukturę terminową', 'en': 'Show term breakdown' };
  arrLang['Title'] = {
    'pl': 'Kursy średnie wybranych walut z ostatniego tygodnia',
    'en': 'Average rates of selected currencies from last week',
  };
  arrLang['yAxisTitle'] = {
    'pl': 'Średni kurs waluty <span>(PLN)</span>',
    'en': 'Average exchange rate <span>(PLN)</span>'
  };
  arrLang['yAxisTitleInflation'] = {
    'pl': 'CPI',
    'en': 'CPI'
  };
  arrLang['xAxisTitle'] = { 'pl': 'Data wystąpienia', 'en': 'Average exchange rate' };
  arrLang['xAxisTitleInflation'] = { 'pl': 'Miesiąc', 'en': 'Month' };
  arrLang['LastUpdateInflation'] = { 'pl': 'Dane za ', 'en': 'Data for ' };

  arrLang['printChart'] = { 'pl': 'Wydrukuj wykres', 'en': 'Print the chart' };
  arrLang['downloadPNG'] = { 'pl': 'Pobierz PNG', 'en': 'Download PNG' };
  arrLang['downloadJPEG'] = { 'pl': 'Pobierz JPEG', 'en': 'Download JPEG' };
  arrLang['downloadPDF'] = { 'pl': 'Pobierz PDF', 'en': 'Download PDF' };
  arrLang['downloadSVG'] = { 'pl': 'Pobierz SVG', 'en': 'Download SVG' };
  arrLang['contextButtonTitle'] = { 'pl': 'Chart context menu', 'en': 'Chart context menu' };
  arrLang['viewFullscreen'] = { 'pl': 'Pełny ekran', 'en': 'View full screen' };

  return arrLang;
};

export { setArrLang };
