import LazyLoad from 'vanilla-lazyload';

const initLazyLoad = () => {
  new LazyLoad({
    elements_selector: '.lazy',
    threshold: 0,
  });
};

export { initLazyLoad };
