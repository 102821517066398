const initChangeContrast = () => {
  const toggleClass = (el, classname) => {
    if (el.classList.contains(classname)) {
      el.classList.remove(classname);
    } else {
      el.classList.add(classname);
    }
  };

  const switcherContrast = document.querySelector('.switcher-contrast');
  let body = document.getElementsByTagName('body')[0];

  if (localStorage.getItem('switch-state') && localStorage.getItem('switch-state') === 'true') {
    toggleClass(body, 'contrast-mode');
  }

  switcherContrast.addEventListener('click', () => {
    toggleClass(body, 'contrast-mode');
    localStorage.setItem('switch-state', body.classList.contains('contrast-mode'));
  });
};

export { initChangeContrast };
