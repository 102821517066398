/* global ajax_object */
import xml2js from 'xml2js';
import 'regenerator-runtime/runtime';
import { toggleSpinner } from '../../components/spinners/toggleSpinner';

const initInterestRates = () => {
  const divContainer = document.getElementById('interest-rates');
  if (!divContainer) return; // Fetch data only if #interest-rates exists

  const spinner = document.querySelector('.spinner-interest');

  const getResponse = async () => {
    try {
      toggleSpinner(spinner);
      const response = await $.ajax({
        type: 'GET',
        url: ajax_object.ajax_url,
        dataType: 'json',
        data: { action: 'get_interest_rates_data' },
      });
      toggleSpinner(spinner);
      return response;
    } catch (error) {
      toggleSpinner(spinner);
      console.error('Error fetching interest rates data:', error);
    }
  };

  const parseXML = async (xmlString) => {
    const parser = new xml2js.Parser();
    toggleSpinner(spinner);
    const xml = await parser.parseStringPromise(xmlString);
    toggleSpinner(spinner);
    return xml;
  };

  const createTable = (tableData, language) => {
    const trends = {
      wzrost: '<i class="interest-rates__arrow-up"></i>',
      spadek: '<i class="interest-rates__arrow-down"></i>',
      default: '<i class="interest-rates__arrow-equal"></i>',
    };

    const row = document.createElement('div');
    row.classList.add('row', 'interest-rates-row');

    tableData.forEach((item) => {
      const trendIcon = trends[item.$.trend] || trends.default;
      const percentage = language === 'pl'
        ? `${item.$.oprocentowanie}<span>%</span>`
        : `${item.$.oprocentowanie.replace(',', '.')}<span>%</span>`;
      const name = language === 'pl' ? item.$.nazwa : item.$.name;

      const col = document.createElement('div');
      col.classList.add('col-6', 'col-md-4', 'col-lg-6', 'interest-rates-col');

      const innerDiv = document.createElement('div');
      innerDiv.classList.add('interest-rates-inner');

      const percentageDiv = document.createElement('div');
      percentageDiv.innerHTML = percentage + trendIcon;
      innerDiv.appendChild(percentageDiv);

      innerDiv.innerHTML += name;

      col.appendChild(innerDiv);
      row.appendChild(col);
    });

    return row;
  };

  const renderInterestRates = async () => {
    try {
      const data = await getResponse();
      const xml = await parseXML(data);
      const table = xml.stopy_procentowe.tabela[0].pozycja;
      const language = document.documentElement.lang.slice(0, 2);

      const row = createTable(table, language);
      divContainer.appendChild(row);
    } catch (error) {
      console.error('Error rendering interest rates:', error);
    }
  };

  renderInterestRates();
};

export { initInterestRates };
