const initShowMoreItems = () => {
  const varLang = document.documentElement.lang.slice(0, 2),
    thematicMenu = document.querySelectorAll('.nav-main .nav-thematic .dropdown-menu ul');
  let viewMore,
    showLess;

  if (varLang !== 'pl') {
    viewMore = '<span class="gsearch">See more</span>';
    showLess = '<span class="gsearch">See less</i></span>';
  } else {
    viewMore = '<span class="gsearch">Zobacz wszystkie</span>';
    showLess = '<span class="gsearch">Zobacz mniej</span>';
  }

  if (thematicMenu !== null) {
    thematicMenu.forEach(el => {
      const maxViewedItems = 3;
      const lastNavItem = document.createElement('li');
      lastNavItem.innerHTML += viewMore;
      lastNavItem.classList.add('view-more', 'nav-item-more', 'contsearch');

      if (el.children.length > maxViewedItems) {
        for (let i = 0; i < el.children.length; i++) {
          if (i > maxViewedItems) {
            el.children[i].classList.add('hidden');
            el.append(lastNavItem);
          }
        }
      }

      lastNavItem.addEventListener('click', function () {
        if (lastNavItem.classList.contains('nav-item-more')) {
          lastNavItem.innerHTML = '';
          lastNavItem.classList.remove('nav-item-more');
          lastNavItem.classList.add('view-more', 'nav-item-less', 'contsearch');
          lastNavItem.innerHTML += showLess;

          for (let i = 0; i < el.children.length; i++) {
            el.children[i].classList.remove('hidden');
          }
        } else if (lastNavItem.classList.contains('nav-item-less')) {
          lastNavItem.innerHTML = '';
          lastNavItem.classList.remove('nav-item-less');
          lastNavItem.classList.add('view-more', 'nav-item-more', 'contsearch');
          lastNavItem.innerHTML += viewMore;

          if (el.children.length > maxViewedItems) {
            for (let i = 0; i < el.children.length; i++) {
              if (i > maxViewedItems) {
                el.children[i].classList.add('hidden');
                el.append(lastNavItem);
              }
            }
          }
        }
      });
    });
  }
};

export { initShowMoreItems };
