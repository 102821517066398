const appendDataSource = (container, type, url, lang) => {
  const appendParagraph = (innerText, type) => {
    const tableParagraph = document.createElement('p');
    const tableAnchor = document.createElement('a');
    tableAnchor.setAttribute('href', url.replace(/\s+/g, ''));
    tableAnchor.setAttribute('class', 'card-file card-file--noext');
    tableAnchor.innerText = innerText + type.toUpperCase();
    tableParagraph.appendChild(tableAnchor);
    container.appendChild(tableParagraph);
  };

  if (lang === 'pl') {
    switch (true) {
      case url.indexOf('plynnosciowa/dir') > -1:
      case url.indexOf('polonia') > -1:
      case url.indexOf('bony/dir') > -1:
        break;
      default:
        appendParagraph('Powyższa tabela w formacie ', type);
        break;
    }
  } else {
    switch (true) {
      case url.indexOf('stopy_procentowe_archiwum') > -1:
        appendParagraph('Interest rates archive table in XML format ', '');
        break;
      case url.indexOf('stopy_procentowe') > -1:
        appendParagraph('Interest rates table in XML format ', '');
        break;
      case url.indexOf('Sytuacja_plynnosciowa') > -1:
        appendParagraph('Table in CSV format ', '');
        break;
      case url.indexOf('bony/dir') > -1:
      case url.indexOf('polonia') > -1:
      case url.indexOf('plynnosciowa/dir') > -1:
        break;
      default:
        appendParagraph('Table in XML format ', type);
        break;
    }
  }
};

export { appendDataSource };
