// Change cell formats.
import XLSX from 'xlsx';

const changeCellFormat = (sheet, colNum, fmt) => {
  // Get worksheet range.
  let range = XLSX.utils.decode_range(sheet['!ref']);
  for (let i = range.s.r + 1; i <= range.e.r; ++i) {
    // Find the data cell (range.s.r + 1 skips the header row of the worksheet).
    let ref = XLSX.utils.encode_cell({ r: i, c: XLSX.utils.decode_col(colNum) });
    // If the particular row did not contain data for the column, the cell will not be generated.
    if (!sheet[ref]) continue;
    // `.t == "n"` for number cells.
    if (sheet[ref].t != 'n') continue;
    // assign the `.z` number format.
    sheet[ref].z = fmt;
  }
};

export { changeCellFormat };
