import $ from 'jquery';
import 'owl.carousel';

const initOwlCarousel = () => {

  const blockCarousel = $('.block-carousel');

  if (blockCarousel !== null) {
    let dataDuration = Number(blockCarousel.data('duration')) || 5000;

    blockCarousel.owlCarousel(
      {
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: dataDuration,
        navText: [
          '<span class="carousel-control-prev-icon" aria-hidden="true"></span>',
          '<span class="carousel-control-next-icon" aria-hidden="true"></span>',
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
          1025: {
            items: 3,
          },
        },
      }
    );
  }

  const postCarousel = $('.post-carousel');

  if (postCarousel !== null) {
    postCarousel.owlCarousel(
      {
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        navText: [
          '<span class="carousel-control-prev-icon" aria-hidden="true"></span>',
          '<span class="carousel-control-next-icon" aria-hidden="true"></span>',
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1.5,
          },
          600: {
            items: 3,
          },
          1025: {
            items: 4,
          },
        },
      }
    );
  }

  const brandsCarousel = $('.brands-carousel');

  if (brandsCarousel !== null) {
    $(document).ready(function () {
      brandsCarousel.owlCarousel(
        {
          loop: true,
          margin: 0,
          center: true,
          nav: true,
          autoplay: false,
          autoplayTimeout: 5000,
          navText: [
            '<span class="carousel-control-prev-icon" aria-hidden="true"></span>',
            '<span class="carousel-control-next-icon" aria-hidden="true"></span>',
          ],
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1025: {
              items: 3,
            },
          },
          onInitialized: function() {
            brandsCarousel.trigger('refresh.owl.carousel')
          }
        }
      );
      setTimeout(() => {
        brandsCarousel.trigger('refresh.owl.carousel');
      }, 200)
    });
  }

  const mainMoneyCarousel = $('.main-money-carousel');

  if (mainMoneyCarousel !== null) {
    mainMoneyCarousel.owlCarousel(
      {
        loop: true,
        margin: 10,
        center: true,
        nav: true,
        autoplay: true,
        autoplayTimeout: 5000,
        navText: [
          '<span class="carousel-control-prev-icon" aria-hidden="true"></span>',
          '<span class="carousel-control-next-icon" aria-hidden="true"></span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            center: false,
            autoplay: false,
            items: 2,
          },
          1025: {
            items: 1,
          },
        },
      }
    );
  }

  const addMoneyCarousels = document.querySelectorAll('.add-money-carousel');

  addMoneyCarousels.forEach(moneyCarousel => {
    const el = $(moneyCarousel);
    let carousel;
    const carouselOptions = {
      // loop: true,
      margin: 24,
      nav: true,
      dots: true,
      autoplay: false,
      slideBy: 'page',
      navText: [
        '<span class="carousel-control-prev-icon" aria-hidden="true"></span>',
        '<span class="carousel-control-next-icon" aria-hidden="true"></span>',
      ],
      responsive: {
        0: {
          items: 2,
        },
        768: {
          items: 2,
          rows: 1
        },
        1025: {
          items: 1,
          rows: 2
        },
      },
    };

    // Taken from Owl Carousel so we calculate width the same way
    const viewport = () => {
      let width;
      if (carouselOptions.responsiveBaseElement && carouselOptions.responsiveBaseElement !== window) {
        width = $(carouselOptions.responsiveBaseElement).width();
      } else if (window.innerWidth) {
        width = window.innerWidth;
      } else if (document.documentElement && document.documentElement.clientWidth) {
        width = document.documentElement.clientWidth;
      } else {
        console.warn('Can not detect viewport width.');
      }
      return width;
    };

    let severalRows = false;
    let orderedBreakpoints = [];
    for (let breakpoint in carouselOptions.responsive) {
      if (carouselOptions.responsive[breakpoint].rows > 1) {
        severalRows = true;
      }
      orderedBreakpoints.push(parseInt(breakpoint));
    }

    // Custom logic is active if carousel is set up to have more than one row for some given window width
    if (severalRows) {
      orderedBreakpoints.sort(function (a, b) {
        return b - a;
      });
      let slides = el.find('[data-slide-index]');
      const slidesNb = slides.length;
      if (slidesNb > 0) {
        let rowsNb;
        let previousRowsNb = undefined;
        let colsNb;
        let previousColsNb = undefined;

        //Calculates number of rows and cols based on current window width
        const updateRowsColsNb = () => {
          let width = viewport();
          for (let i = 0; i < orderedBreakpoints.length; i++) {
            const breakpoint = orderedBreakpoints[i];
            if (width >= breakpoint || i == (orderedBreakpoints.length - 1)) {
              let breakpointSettings = carouselOptions.responsive['' + breakpoint];
              rowsNb = breakpointSettings.rows;
              colsNb = breakpointSettings.items;
              break;
            }
          }
        };

        const updateCarousel = () => {
          updateRowsColsNb();

          //Carousel is recalculated if and only if a change in number of columns/rows is requested
          if (rowsNb != previousRowsNb || colsNb != previousColsNb) {
            let reInit = false;
            if (carousel) {
              //Destroy existing carousel if any, and set html markup back to its initial state
              carousel.trigger('destroy.owl.carousel');
              carousel = undefined;
              slides = el.find('[data-slide-index]').detach().appendTo(el);
              el.find('.slide-fake-wrapper').remove();
              reInit = true;
            }

            //This is the only real 'smart' part of the algorithm

            //First calculate the number of needed columns for the whole carousel
            const perPage = rowsNb * colsNb;
            const pageIndex = Math.floor(slidesNb / perPage);
            const fakeColsNb = pageIndex * colsNb + (slidesNb >= (pageIndex * perPage + colsNb) ? colsNb : (slidesNb % colsNb));

            //Then populate with needed html markup
            let count = 0;
            for (let i = 0; i < fakeColsNb; i++) {
              //For each column, create a new wrapper div
              let fakeCol = $('<div class="slide-fake-wrapper"></div>').appendTo(el);
              for (let j = 0; j < rowsNb; j++) {
                //For each row in said column, calculate which slide should be present
                const index = Math.floor(count / perPage) * perPage + (i % colsNb) + j * colsNb;
                if (index < slidesNb) {
                  //If said slide exists, move it under wrapper div
                  slides.filter('[data-slide-index=' + index + ']').detach().appendTo(fakeCol);
                }
                count++;
              }
            }
            //end of 'smart' part

            previousRowsNb = rowsNb;
            previousColsNb = colsNb;

            if (reInit) {
              //re-init carousel with new markup
              carousel = el.owlCarousel(carouselOptions);
            }
          }
        };

        //Trigger possible update when window size changes
        $(window).on('resize', updateCarousel);

        //We need to execute the algorithm once before first init in any case
        updateCarousel();
      }
    }

    //init
    carousel = el.owlCarousel(carouselOptions);
  });
};

export { initOwlCarousel };

