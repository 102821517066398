const initCarouselProgressBar = () => {
  const carouselElement = document.querySelector('.carousel');
  if (carouselElement !== null) {
    const carouselIndicator = carouselElement.querySelector('.carousel-progress-bar');
    const carouselAction = document.querySelector('.carousel-action');
    const carouselPrev = document.querySelector('.carousel-control-prev');
    const carouselNext = document.querySelector('.carousel-control-next');
    const carouselIndicators = document.querySelector('.carousel-indicators');
    const dataBsRide = carouselElement.getAttribute('data-bs-ride');
    const dataBsInterval = carouselElement.getAttribute('data-bs-interval');
    let intervalID;

    // eslint-disable-next-line no-undef
    const carousel = new bootstrap.Carousel(carouselElement, {
      interval: dataBsInterval ? Number(dataBsInterval) : false,
      ride: dataBsRide ? dataBsRide : false,
      touch: false,
    });

    window.addEventListener('load', function (e) {
      if (carouselIndicator !== null) {
        let index = e.to;
        fillCarouselIndicator(++index);
      }
    });

    carouselElement.addEventListener('slide.bs.carousel', function (e) {
      if (carouselIndicator !== null) {
        let index = e.to;
        fillCarouselIndicator(++index);
      }
    });

    const fillCarouselIndicator = () => {
      let i = 0;
      carouselIndicator.style.width = 0;
      clearInterval(intervalID);
      carousel.pause();
      intervalID = setInterval(function () {
        i++;
        carouselIndicator.dataset.progress = i;
        carouselIndicator.style.width = i / 4 + '%';
        if ((i / 4) >= 100) {
          i = 0;
          carousel.next();
        }
      }, 50);
    };

    const continueCarouselIndicator = (j) => {
      intervalID = setInterval(function () {
        j++;
        carouselIndicator.dataset.progress = j;
        carouselIndicator.style.width = (j / 4) + '%';
        if ((j / 4) >= 100) {
          j = 0;
          carousel.next();
        }
      }, 50);
    };

    if (carouselAction !== null) {
      carouselAction.addEventListener('click', () => {
        if (carouselAction.classList.contains('btn-stop')) {
          carouselAction.classList.remove('btn-stop');
          carouselAction.classList.add('btn-play');
          clearInterval(intervalID);
          carousel.pause();
        } else {
          carouselAction.classList.remove('btn-play');
          carouselAction.classList.add('btn-stop');

          if (carouselIndicator !== null) {
            continueCarouselIndicator(carouselIndicator.dataset.progress);
          } else {
            if (dataBsRide && dataBsInterval) {
              carousel.cycle();
            }
          }
        }
      });
    }

    if (carouselPrev !== null && carouselNext !== null) {
      [carouselPrev, carouselNext].forEach(item => {
        item.addEventListener('click', (event) => {
          carouselAction.classList.remove('btn-play');
          carouselAction.classList.add('btn-stop');

          let index = event.to;
          if (carouselIndicator !== null) {
            fillCarouselIndicator(++index);
          } else {
            if (dataBsRide && dataBsInterval) {
              carousel.cycle();
            }
          }
        });
      });
    }
    if (carouselIndicators !== null) {
      carouselIndicators.querySelectorAll('li').forEach(item => {
        item.addEventListener('click', (event) => {
          carouselAction.classList.remove('btn-play');
          carouselAction.classList.add('btn-stop');

          let index = event.to;
          if (carouselIndicator !== null) {
            fillCarouselIndicator(++index);
          } else {
            if (dataBsRide && dataBsInterval) {
              carousel.cycle();
            }
          }
        });
      });
    }
  }
};

export { initCarouselProgressBar };
