import { appendInterestRates } from './custom/appendInterestRates';
import { appendInterestRatesArchive } from './custom/appendInterestRatesArchive';
import { appendLiquidityCondition } from './custom/appendLiquidityCondition';
import { appendLiquidityConditionArchive } from './custom/appendLiquidityConditionArchive';
import { appendPolonia } from './custom/appendPolonia';
import { appendBon } from './custom/appendBon';
import { appendDataSource } from './custom/appendDataSource';
import { appendDefaultData } from './default/appendDefaultData';

const initGenerateTable = () => {
  // Get necessary data to use -> data_url from gutenberg block, lang from html attr and parser container
  const parserContainers = document.querySelectorAll('.parser'),
    lang = document.documentElement.lang.slice(0, 2);

  if (parserContainers !== null) {
    parserContainers.forEach(element => {
      const url = element.getAttribute('data-name'),
        fileType = url.substring(url.lastIndexOf('.') + 1, url.length) === 'aspx' ? 'xml' : url.substring(url.lastIndexOf('.') + 1, url.length);

      const tableContainer = document.createElement('figure');
      tableContainer.setAttribute('id', 'showData-' + url);
      tableContainer.classList.add('wp-block-table', 'is-style-regular');

      const listContainer = document.createElement('figure');
      listContainer.setAttribute('id', 'showList-' + url);
      listContainer.classList.add('wp-block-table', 'is-style-regular');

      switch (true) {
        case url.indexOf('stopy_procentowe_archiwum') > -1:
          appendInterestRatesArchive(tableContainer);
          break;
        case url.indexOf('stopy_procentowe') > -1:
          appendInterestRates(tableContainer);
          break;
        case url.indexOf('Sytuacja_plynnosciowa') > -1:
          appendLiquidityCondition(tableContainer);
          break;
        case url.indexOf('plynnosciowa/dir') > -1:
          appendLiquidityConditionArchive(tableContainer, listContainer);
          break;
        case url.indexOf('polonia') > -1:
          appendPolonia(tableContainer);
          break;
        case url.indexOf('bony/dir') > -1:
          appendBon(listContainer);
          break;
        default:
          appendDefaultData(tableContainer, fileType, url);
          break;
      }

      element.appendChild(tableContainer);
      element.appendChild(listContainer);

      appendDataSource(element, fileType, url, lang);
    });
  }
};

export { initGenerateTable };
