const initEqualHeight = () => {
  const coinsCarouselEl = document.getElementById('carouselCoins');
  if (coinsCarouselEl !== null) {
    const carouselCoinsItems = coinsCarouselEl.querySelectorAll('.carousel-item');

    if (carouselCoinsItems !== null) {
      let maxHeight = 0;
      carouselCoinsItems.forEach(item => {
        maxHeight = Math.max(maxHeight, $(item).outerHeight());
        setTimeout(() => {
          item.style.height = maxHeight + 'px';
        }, 100);
      });
    }
  }

  const newsCarouselEl = document.getElementById('news-carousel-desktop');
  if (newsCarouselEl !== null) {
    const carouselNewsItems = newsCarouselEl.querySelectorAll('.carousel-item');

    if (carouselNewsItems !== null) {
      let maxHeight = 0;
      carouselNewsItems.forEach(item => {
        maxHeight = Math.max(maxHeight, $(item).outerHeight());
        setTimeout(() => {
          item.style.height = maxHeight + 'px';
        }, 100);
      });
    }
  }

  const mediaQuery = window.matchMedia('(max-width: 992px)')
  if (mediaQuery.matches) {
    const homeCarouselEl = document.getElementById('carouselHome');
    if (homeCarouselEl !== null) {
      const carouselHomeItems = homeCarouselEl.querySelectorAll('.carousel-item');

      if (carouselHomeItems !== null) {
        let maxHeight = 0;
        carouselHomeItems.forEach(item => {
          maxHeight = Math.max(maxHeight, $(item).outerHeight());
          setTimeout(() => {
            item.style.height = maxHeight + 'px';
          }, 100);
        });
      }
    }
  }

};

export { initEqualHeight };
