const initNavExpandClass = () => {
  const navExpand = document.querySelectorAll('.dropdown');
  if (navExpand !== null) {
    navExpand.forEach((item) => {
      item.addEventListener('click', () => {
        item.classList.toggle('nav-item-active');
      });
    });
  }
};

const initDropdown = () => {
  window.addEventListener("resize", resizeHandler);
  window.addEventListener("load", resizeHandler);

  function resizeHandler() {
    let winWidth  = window.innerWidth,
      threshold = 991,
      els       = document.querySelectorAll('.dropdown-toggle');

    [].forEach.call(els, function (el) {
      if (winWidth > threshold) {
        el.removeAttribute("data-bs-toggle");
      } else {
        el.setAttribute("data-bs-toggle", "dropdown");
      }
    });
  }
}

export { initNavExpandClass, initDropdown };
