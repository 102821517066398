const initNavbarOrder = () => {
  const navbar = document.querySelector('.nav-main'),
    navbarItems = navbar.querySelectorAll('.nav-link'),
    alfabethicBtn = document.querySelector('.nav-switcher-alfabethic'),
    alfabethicMenu = navbar.querySelector('.nav-alfabethic'),
    alfabethicMenuList = navbar.querySelector('.nav-alfabethic-list'),
    thematicBtn = document.querySelector('.nav-switcher-thematic'),
    thematicMenu = navbar.querySelector('.nav-thematic'),
    navSpans = document.querySelectorAll('.nav li a > span');

  // Remove col-lg-3 from all .dropdown-item parents
  for (let i = 0; i < thematicMenu.children.length; i++) {
    if (thematicMenu.children[i].classList.contains('dropdown')) {
      document.querySelectorAll('.dropdown-item').forEach(el => {
        el.parentElement.classList.remove('col-lg-3', 'nav-col');
      });
    }
  }

  // Alphabetic sort of nav elements
  let letter, navSpan, navLi, navItem, navLink;
  for (let i = 65; 90 >= i; i++) {
    letter = String.fromCharCode(i);

    navSpan = document.createElement('span');
    navSpan.innerHTML += letter;

    navLi = document.createElement('li');
    navLi.classList.add('menu-item', 'menu-item-has-children', 'col-lg-3', 'nav-col', 'contsearch');
    navLi.setAttribute('id', 'list-item-' + letter);

    navLi.appendChild(navSpan);
    alfabethicMenu.appendChild(navLi);

    navItem = document.createElement('li');
    navItem.classList.add('nav-alfabethic-list__item');
    navLink = document.createElement('a');
    navLink.setAttribute('href', '#list-item-' + letter);

    navLink.appendChild(navSpan.cloneNode(true));
    navItem.appendChild(navLink);
    alfabethicMenuList.appendChild(navItem);

    if (navbarItems !== null) {
      navbarItems.forEach(el => {
        if (el.innerText.charAt(0) === letter) {
          el.classList.add('contsearch');
          navLi.appendChild(el.cloneNode(true));
        }
      });
    }
  }

  alfabethicBtn.addEventListener('click', function () {
    const alfabethicToggleItems = document.querySelectorAll('.dropdown-toggle');
    alfabethicToggleItems.forEach(item => item.dataset.bsToggle = 'false');

    thematicMenu.classList.add('d-none');
    thematicMenu.classList.remove('d-flex');
    thematicBtn.classList.remove('active');

    alfabethicMenu.classList.remove('d-none');
    alfabethicMenu.classList.add('d-flex');
    alfabethicBtn.classList.add('active');

    alfabethicMenuList.classList.add('d-flex');
    alfabethicMenuList.classList.remove('d-none');

    if (navSpans) {
      navSpans.forEach(e => {
        e.classList.add('d-inline-block');
        let orgText = e.innerText;

        orgText = orgText.replace('', function () {
          return '';
        });
        e.innerText = orgText;
      });
    }
  });

  thematicBtn.addEventListener('click', function () {
    const thematicToggleItems = document.querySelectorAll('.dropdown-toggle');
    thematicToggleItems.forEach(item => item.dataset.bsToggle = 'dropdown');

    thematicMenu.classList.remove('d-none');
    thematicMenu.classList.add('d-flex');
    thematicBtn.classList.add('active');

    alfabethicMenu.classList.add('d-none');
    alfabethicMenu.classList.remove('d-flex');
    alfabethicBtn.classList.remove('active');

    alfabethicMenuList.classList.add('d-none');
    alfabethicMenuList.classList.remove('d-flex');

    if (navSpans) {
      navSpans.forEach(e => {
        e.classList.add('d-inline-block');
        let orgText = e.innerText;

        orgText = orgText.replace('', function () {
          return '';
        });
        e.innerText = orgText;
      });
    }
  });
};

export { initNavbarOrder };
