if (typeof Object.create !== 'function') {
  Object.create = function (obj) {
    function F () {
    }

    F.prototype = obj;
    return new F();
  };
}

(function ($) {
  let JsLocalSearch = {
    init: function (options, el) {
      // @TODO edit eslint rules
      // eslint-disable-next-line no-unused-vars
      let base = this;

      if (options == undefined) {options = {};}

      let searchinput = (options.searchinput == undefined) ? '#gsearchsimple' : options.searchinput;
      options.container = (options.container == undefined) ? 'contsearch' : options.container;
      let containersearch = (options.containersearch == undefined) ? 'gsearch' : options.containersearch;
      let mincaracteres = (options.mincaracteres == undefined) ? 3 : options.mincaracteres;
      let action = (options.action == undefined) ? 'Show' : options.action;
      let info = (options.info == undefined) ? false : options.info;
      options.mark_text = (options.mark_text == undefined) ? false : options.mark_text;
      options.html_search = (options.html_search == undefined) ? true : options.html_search;

      $(el).hide();

      let actionObject = Object.create(eval('Action' + action));
      let inputValue = $('.search-field').val();

      actionObject.init(options, this);
      $(searchinput).keyup(
        function (event) {
          event.preventDefault();
          // eslint-disable-next-line no-undef
          txt = $(searchinput).val();
          $('.' + options.container).attr('data-show', 0);

          if (info) {
            $(el).show();
          }
          if ($(searchinput).val().length >= mincaracteres) {
            actionObject.zero(this);
            // eslint-disable-next-line no-undef
            total = 0;
            // eslint-disable-next-line no-undef
            totalok = 0;
            // eslint-disable-next-line no-undef
            totalko = 0;

            $('.' + options.container).each(function () {
              // eslint-disable-next-line no-undef
              visible = false;
              // eslint-disable-next-line no-undef
              total++;
              $(this).find('.' + containersearch).each(function () {
                // eslint-disable-next-line no-undef
                if (visible != true) {
                  // eslint-disable-next-line no-undef
                  visible = base.busca(options, actionObject, this);
                }

              });
              // eslint-disable-next-line no-undef
              if (visible == true) {
                actionObject.ok(this);
                // eslint-disable-next-line no-undef
                totalok++;
              } else {
                actionObject.ko(this);
                // eslint-disable-next-line no-undef
                totalko++;
              }

            });
            // eslint-disable-next-line no-undef
            $(el).find('.total').html(total);
            // eslint-disable-next-line no-undef
            $(el).find('.number').html(totalok);
            // eslint-disable-next-line no-undef
            $(el).find('.numberno').html(totalko);
            // eslint-disable-next-line no-undef
            if (totalok == 0) {
              $('.search-empty').css('display', 'block');
              $('.search-empty__value').text($(searchinput).val() + '.');
              $('.search-empty__link').attr('href', '/?s=' + $(searchinput).val());
            } else {
              $('.search-empty').css('display', 'none');
              $('.search-empty__value').text('');
            }

          } else {
            $(el).hide();
            actionObject.nope();
            $('.' + options.container).find('.' + containersearch).each(function () {
              if (options.mark_text != undefined) {
                // eslint-disable-next-line no-undef,no-unused-vars, no-useless-escape
                let re = new RegExp('<span class="' + options.mark_text + '">(.*?)<\/span>', 'gi');
                let str = $(this).html();
                var newstr = str.replace(re, '$1');
              }
              $(this).html(newstr);
            });
          }
        });

      if ($('#gsearchsimple') !== null) {
        // eslint-disable-next-line no-undef,no-unused-vars
        $('#gsearchsimple').on('keyup', function (e) {
          if (inputValue !== '' && inputValue !== ' ') {
            $('.input-group-close').css('display', 'none');
          } else {
            $('.input-group-close').css('display', 'block');
          }
        });
      }

      if ($('.input-group-close') !== null) {
        $('.nav-main__switcher-alfabethic, .input-group-close').on('click', function () {
          $(el).hide();
          actionObject.nope();
          $('.' + options.container).find('.' + containersearch).each(function () {
            if (options.mark_text != undefined) {
              // eslint-disable-next-line no-useless-escape
              let re = new RegExp('<span class="' + options.mark_text + '">(.*?)<\/span>', 'gi');
              let str = $(this).html();
              var newstr = str.replace(re, '$1');
            }
            $(this).html(newstr);
          });
          $(searchinput).val('');
          $('.search-empty').css('display', 'none');
          $('.input-group-close').css('display', 'none');
          $('.search-empty__value').text('');
        });
      }
    },

    busca: function (options, actionObject, el) {

      if (options.mark_text != undefined) {
        // eslint-disable-next-line no-useless-escape
        let re = new RegExp('<span class="' + options.mark_text + '">(.*?)<\/span>', 'gi');
        let str = $(el).html();
        var newstr = str.replace(re, '$1');
        $(el).html(newstr);
      }
      // eslint-disable-next-line no-undef
      search = false;

      if (options.html_search)
        // eslint-disable-next-line no-undef
        if ($(el).text().normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2').normalize().toUpperCase().indexOf(txt.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2').normalize().toUpperCase()) != -1) {
          // eslint-disable-next-line no-undef
          search = true;
          // eslint-disable-next-line no-undef
          let re = new RegExp('(' + txt + ')', 'gi');
          let str = $(el).text();

          if (options.mark_text != undefined) {
            // eslint-disable-next-line no-undef,no-redeclare, no-useless-escape
            var newstr = str.replace(re, '<span class="' + options.mark_text + '">$1<\/span>');
          }
          $(el).html(newstr);
        }

      if ($(el).attr('data-description') != undefined && options.description_search) {
        // eslint-disable-next-line no-undef
        if ($(el).attr('data-description').toUpperCase().indexOf(txt.toUpperCase()) != -1) {
          // eslint-disable-next-line no-undef
          search = true;
        }
      }

      if ($(el).attr('data-keywords') != undefined && options.keyword_search) {
        // eslint-disable-next-line no-undef
        keywords = $(el).attr('data-keywords').toUpperCase().split(',');
        // eslint-disable-next-line no-undef
        searchKey = txt.toUpperCase().split(' ');
        // eslint-disable-next-line no-undef
        $.each(keywords, function (index, value) {
          // eslint-disable-next-line no-undef
          $.each(searchKey, function (index, key) {
            if (value == key) {
              // eslint-disable-next-line no-undef
              search = true;
            }
          });
        });

      }
      // eslint-disable-next-line no-undef
      return search;
    },
  };

  let ActionShow = {
    // eslint-disable-next-line no-undef,no-unused-vars
    init: function (options, el) {
      this.container = options.container;
    },
    // eslint-disable-next-line no-undef,no-unused-vars
    zero: function (options, el) {
      $('.' + this.container).removeClass('contsearch-show');
      $('.' + this.container).addClass('contsearch-hidden');
      $('.view-more').hide();
    },
    ok: function (t) {
      $(t).closest('.' + this.container).removeClass('contsearch-hidden');
      $(t).closest('.' + this.container).addClass('contsearch-show');
      $(t).closest('.' + this.container).attr('data-show', 1);
      $('.view-more').hide();
    },
    ko: function (t) {
      if ($(t).closest('.' + this.container).attr('show') != 1) {
        $(t).closest('.' + this.container).removeClass('contsearch-show');
        $(t).closest('.' + this.container).addClass('contsearch-hidden');
        $('.view-more').hide();
      }

    },
    nope: function () {
      $('.' + this.container).removeClass('contsearch-hidden');
      $('.' + this.container).removeClass('contsearch-show');
      $('.view-more').show();
    },
  };
  // eslint-disable-next-line no-undef,no-unused-vars
  let ActionMark = {
    // eslint-disable-next-line no-undef,no-unused-vars
    init: function (options, el) {
      this.container = options.container;
      this.mark = options.actionok;
      this.unmark = options.actionko;
    },
    // eslint-disable-next-line no-undef,no-unused-vars
    zero: function (options, el) {
      $('.' + this.container).removeClass(this.mark);
      $('.' + this.container).removeClass(this.unmark);

    },
    ok: function (t) {
      $(t).closest('.' + this.container).removeClass(this.unmark);
      $(t).closest('.' + this.container).addClass(this.mark);
      $(t).closest('.' + this.container).attr('data-show', 1);
    },
    ko: function (t) {
      if ($(t).closest('.' + this.container).attr('data-show') != 1) {
        $(t).closest('.' + this.container).removeClass(this.mark);
        $(t).closest('.' + this.container).addClass(this.unmark);
      }

    },
    nope: function () {
      $('.' + this.container).removeClass(this.mark);
      $('.' + this.container).removeClass(this.unmark);

    },
  };

  $.fn.jsLocalSearch = function (options) {
    return this.each(function () {

      let jsLocalSearch = Object.create(JsLocalSearch);
      // eslint-disable-next-line no-undef
      Object.create(ActionShow);
      jsLocalSearch.init(options, this);
    });
  };

}(jQuery, window, document));

