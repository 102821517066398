import { initPhotoSwipeFromDOM } from './modulePhotoswipe';

document.addEventListener('DOMContentLoaded', function () {
  let selector;

  if (document.querySelector('.wp-block-gallery')) {
    selector = '.wp-block-image-single, .wp-block-gallery, .gallery, .single-image, .fancybox-img';
  } else {
    selector = '.wp-block-image, .gallery-container, .wp-block-media-text__media,' +
      ' .thumbnail-lightbox, .gallery, .single-image, .fancybox-img';
  }

  // Function to check if the URL is an image
  function isImageURL (url) {
    return url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/i);
  }

  // Function to check if all <a> tags in the selector have image URLs
  function allLinksHaveImageURL (container) {
    const links = container.querySelectorAll('a');
    for (let i = 0; i < links.length; i++) {
      if (!isImageURL(links[i].getAttribute('href'))) {
        return false;
      }
    }
    return true;
  }

  // Loop through each element in the selector
  document.querySelectorAll(selector).forEach(container => {
    if (allLinksHaveImageURL(container)) {
      initPhotoSwipeFromDOM(container);
    }
  });
});
