import $ from 'jquery';
import './navSearch';

const initNavSearch = () => {
  $('#localSearchSimple').jsLocalSearch({
    'action': 'Show',
    'info': true,
  });
};

export { initNavSearch };
