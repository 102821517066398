// -- Vendors -- //
import { initLazyLoad } from './vendors/initLazyLoad';
import { initOwlCarousel } from './vendors/initOwlCarousel';
import { initCookieNotice } from './vendors/initCookieNotice';

// -- Components -- //
import { initOffcanvasToggling } from './components/header/initOffcanvasToggling';
import { initNavbarOrder } from './components/header/initNavbarOrder';
import { initNavSearch } from './components/header/initNavSearch';
import { initChangeContrast } from './components/contrast-switcher/ChangeContrast';
import { initCarouselProgressBar } from './components/carousel/carouselProgressBar';
import { initSwipe } from './components/carousel/initSwipe';
import { initEqualHeight } from './components/carousel/initEqualHeight';
import { initGenerateTable } from './components/parsers/generateTable';
import { initExtendAccordion } from './components/accordion/extendAccordion';
import { initShowMoreItems } from './components/header/showMoreItems';
import { initDropdown, initNavExpandClass } from './components/header/navExpandClass';
import './components/validation/init';
import './components/pagination/initPagination';
import './vendors/photoswipe/initPhotoswipe';

import { initExchangeRatesChart } from './components/highcharts/exchange-rates/initChart';
import { initExchangeRatesMonthChart } from './components/highcharts/exchange-rates/initMonthChart';
import { initInflationChart } from './components/highcharts/inflation/initChart';

// -- Layout -- //
import { initInterestRates } from './layout/frontpage/interestRates';

// -- Invokes on document ready -- //
$(function () {
  // -- Vendors
  initLazyLoad?.();
  initOwlCarousel?.();
  initCookieNotice?.();

  // -- Layout
  initInterestRates?.();
  initNavExpandClass?.();
  initDropdown?.();
  initExtendAccordion?.();
  initCarouselProgressBar?.();
  initSwipe?.();
  initEqualHeight?.();

  // -- Components
  initOffcanvasToggling?.();
  initNavbarOrder?.();
  initNavSearch?.();
  initShowMoreItems?.();
  initExchangeRatesChart?.();
  initExchangeRatesMonthChart?.();
  initInflationChart?.();
  initChangeContrast?.();
  initGenerateTable?.();
});
