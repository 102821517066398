const toggleSpinner = (spinner) => {
  if (spinner) {
    if (spinner.classList.contains('hidden')) {
      spinner.classList.remove('hidden');
    } else {
      spinner.classList.add('hidden');
    }
  }
};

export { toggleSpinner };
