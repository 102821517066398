/* global ajax_object */
import xml2js from 'xml2js';

const lang = document.documentElement.lang.slice(0, 2);

const createTable = (container, table, obj) => {
  const insertHeaders = (table, obj) => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const row = table.insertRow();

    for (let i = 0; i < obj.length; i++) {
      let cell = document.createElement('th');
      row.appendChild(cell);
      cell.appendChild(document.createTextNode(capitalizeFirstLetter(obj[i])));
    }
  };

  const insertTable = (table, obj) => {
    let rowYears = table.insertRow();
    let cell = document.createElement('th');
    rowYears.appendChild(cell);
    cell.appendChild(document.createTextNode(obj.years[0]));

    for (let i = 0; i < obj.years.length; i++) {
      let child = obj.body[i];

      if (obj.years[i] === (new Date(child.pl.date + 'T00:00:00Z')).getFullYear()) {
        if (obj.years[i - 1] < obj.years[i]) {
          rowYears = table.insertRow();
          cell = document.createElement('th');
          rowYears.appendChild(cell);
          cell.appendChild(document.createTextNode(obj.years[i]));
        }
      }
      let row = table.insertRow();

      if (lang === 'pl') {
        Object.values(child.pl).forEach(function (k) {
          let cell = row.insertCell();
          cell.innerHTML = k;
        });
      } else {
        Object.values(child.eng).forEach(function (k) {
          let cell = row.insertCell();
          cell.innerHTML = k;
        });
      }
    }
  };

  if (lang === 'pl') {
    insertHeaders(table, obj.header.pl);
    insertTable(table, obj);
  } else {
    insertHeaders(table, obj.header.eng);
    insertTable(table, obj);
  }

  const tableResponsive = document.createElement('div');
  tableResponsive.classList.add('table-responsive');

  tableResponsive.appendChild(table);
  container.appendChild(tableResponsive);

  return container;
};

const appendInterestRatesArchive = (tableContainer) => {
  // eslint-disable-next-line no-undef
  const xmls = ajax_object.data_body,
    parser = new xml2js.Parser();

  for (const key in xmls) {
    // eslint-disable-next-line no-prototype-builtins
    if (xmls.hasOwnProperty(key)) {
      if (key.indexOf('stopy_procentowe_archiwum') > -1) {
        // eslint-disable-next-line no-undef
        parser.parseStringPromise(xmls[key]).then(function (result) {
          const interestRatesArr = result.stopy_procentowe_archiwum.pozycje;

          let objYears = [];
          interestRatesArr.forEach((obj, index) => {
            objYears[index] = (new Date(obj.$.obowiazuje_od + 'T00:00:00Z')).getFullYear();
          });

          // START Generate Interest rates table and display Reserve requirement table
          let objBody = [];
          interestRatesArr.forEach((obj, index) => {
            objBody[index] = {
              pl: {
                date: obj.$.obowiazuje_od,
                ref: '*',
                lom: '*',
                dep: '*',
                red: '*',
                dys: '*',
              },
              eng: {
                date: obj.$.obowiazuje_od,
                ref: '*',
                lom: '*',
                dep: '*',
                red: '*',
                dys: '*',
              },
            };

            const filterSpecificInteresRates = () => {
              obj.pozycja.forEach(item => {
                if (item !== 'undefined') {
                  switch (item.$.id) {
                    case 'ref':
                      objBody[index].pl.ref = item.$.oprocentowanie;
                      objBody[index].eng.ref = item.$.oprocentowanie.replace(',', '.');
                      break;
                    case 'lom':
                      objBody[index].pl.lom = item.$.oprocentowanie;
                      objBody[index].eng.lom = item.$.oprocentowanie.replace(',', '.');
                      break;
                    case 'dep':
                      objBody[index].pl.dep = item.$.oprocentowanie;
                      objBody[index].eng.dep = item.$.oprocentowanie.replace(',', '.');
                      break;
                    case 'red':
                      objBody[index].pl.red = item.$.oprocentowanie;
                      objBody[index].eng.red = item.$.oprocentowanie.replace(',', '.');
                      break;
                    case 'dys':
                      objBody[index].pl.dys = item.$.oprocentowanie;
                      objBody[index].eng.dys = item.$.oprocentowanie.replace(',', '.');
                      break;
                    default:
                      console.log('Error');
                      break;
                  }
                }
              });
            };

            filterSpecificInteresRates();
          });

          const objArr = {
            header: {
              pl: [
                'Obowiązuje od dnia',
                'Stopa referencyjna',
                'Stopa lombardowa',
                'Stopa depozytowa',
                'Stopa redyskontowa weksli',
                'Stopa dyskontowa weksli',
              ],
              eng: [
                'In effect from',
                'Reference rate',
                'Lombard rate',
                'NBP Deposit rate',
                'Rediscount rate',
                'Discount rate',
              ]
            },
            years: objYears,
            body: objBody
          };

          const table = document.createElement('table');
          table.classList.add('table', 'table-hover', 'table-striped', 'table-bordered');

          createTable(tableContainer, table, objArr);
          // END Generate Interest rates table and display Reserve requirement table
        });
      }
    }
  }
};

export { appendInterestRatesArchive };
