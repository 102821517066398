const initExtendAccordion = () => {
  const accordionSelects = document.querySelectorAll('.accordion__select');

  if (accordionSelects !== null) {
    accordionSelects.forEach((select) => {
      select.addEventListener('change', function () {
        // Saves in a variable the wanted div
        const selectedContent = document.querySelector('.accordion__' + this.value);
        const selectedContentBody = selectedContent.parentNode.parentNode;

        const hideContents = selectedContentBody.querySelectorAll('.accordion__content');

        if (hideContents !== null) {
          hideContents.forEach((item) => {
            item.classList.remove('show');
          });
        }

        // Show only element connected to selected option
        // eslint-disable-next-line no-undef
        let selectedContentCollapse = new bootstrap.Collapse(selectedContent);
        selectedContentCollapse;
      });
    });
  }
};

export { initExtendAccordion };
