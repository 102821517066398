import XLSX from 'xlsx';
import { changeCellFormat } from './changeCellFormat';
import { createTable } from './createTable';

const appendDefaultData = (container, type, url) => {
  let reader = new XMLHttpRequest();
  reader.open('GET', url, true);

  switch (type) {
    case 'xml':
      reader.responseType = 'document';
      break;
    default:
      reader.responseType = 'arraybuffer';
      break;
  }

  reader.onload = () => {
    let data = new Uint8Array(reader.response),
      workbook,
      firstSheetName,
      firstSheet,
      jsonObject;

    // Check file type and use specific function.
    switch (type) {
      case 'csv':
        workbook = XLSX.read(data, {
          type: 'array',
          cellText: false,
          cellDates: true,
        });
        firstSheetName = workbook.SheetNames[0];
        firstSheet = workbook.Sheets[firstSheetName];
        changeCellFormat(firstSheet, 'D', '#,##0.00');
        jsonObject = XLSX.utils.sheet_to_row_object_array(firstSheet, {
          raw: false,
          range: 2,
          dateNF: 'D-M-YYYY',
        });
        createTable(jsonObject, type);
        break;

      case 'xlsx':
        workbook = XLSX.read(data, {
          type: 'array',
          cellText: false,
          cellDates: true,
        });
        firstSheetName = workbook.SheetNames[0];
        firstSheet = workbook.Sheets[firstSheetName];
        changeCellFormat(firstSheet, 'B', '0.00%');
        changeCellFormat(firstSheet, 'C', '#,##0.00');
        jsonObject = XLSX.utils.sheet_to_row_object_array(firstSheet, {
          raw: false,
          dateNF: 'D-M-YYYY',
        });

        container.innerHTML = '';
        container.appendChild(createTable(jsonObject, type));

        break;
      default:
        fetch(url)
          .then(response => response.text())
          .then((data) => {
            let parser = new DOMParser(),
              xml = parser.parseFromString(data, 'text/xml'),
              nodes = xml.querySelectorAll('*'),
              quotes = nodes[0].getElementsByTagName('quote');

            quotes.forEach((quote) => {
              console.log(quote);
            });
          });
        break;
    }
  };

  reader.onerror = function (ex) {
    console.error(ex);
  };

  reader.send();
};

export { appendDefaultData };
