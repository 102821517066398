/* global ajax_object */
jQuery(document).ready(function ($) {
  const postFeed = $('.posts-feed');
  if (postFeed.length) {
    document.querySelector('.page-input').addEventListener('keypress', event => {
      if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,3}$/)) {
        // block the input if result does not match
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    });

    const $pagination = $('.pagination');
    const $spinner = $('.spinner');
    const $pageInput = $('.page-input');
    const $subContainer = $('.subcategory-container');
    const $yearsContainer = $('.years-container');
    const $mainContent = $('.posts-container');
    const $categoryInput = $mainContent.find('.category');
    const $subCategoryInput = $('.subcategory');
    const $yearInput = $('.year');
    const $orderInput = $('.orderby');

    // $wp_query args
    let category_name = $mainContent.data('category');
    let posts_per_page = $mainContent.data('per-page') ? $mainContent.data('per-page') : 10;
    let pageInputTerm = '';
    let paged = 1;
    let orderby = 'date';
    let order = 'DESC';
    let year = '';
    let post_type = 'post';
    let s = $mainContent.data('search') || '';

    // helpers
    const animateToTop = (parent) => {
      $([document.documentElement, document.body]).animate({
        scrollTop: $(parent).offset().top - 100
      }, 'slow');
    };

    const generatePosts = (parent, category_name) => {
      queryPosts(parent, category_name);
      // animate to top
      animateToTop(parent);
    };

    const getSortValues = (sortType) => {
      switch (sortType) {
        case 'date_desc':
          orderby = 'date';
          order = 'DESC';
          break;
        case 'date_asc':
          orderby = 'date';
          order = 'ASC';
          break;
        case 'title_desc':
          orderby = 'title';
          order = 'ASC';
          break;
        case 'title_asc':
          orderby = 'title';
          order = 'DESC';
          break;
        default:
          orderby = 'date';
          order = 'ASC';
          break;
      }
    };

    const getPageHTML = (pageNum = undefined) => {
      let page_html = '';
      if (pageNum) {
        const parentClasses = ['page-item', 'num', pageNum === paged ? 'active' : null].join(' ').trim();
        page_html = `<li class="${parentClasses}"><a class="page-link" href="#">${pageNum}</a></li>`;
      }

      return page_html;
    };

    const getPageDots = () => {
      const parentClasses = ['page-link', 'dots'].join(' ').trim();

      return `<li class="page-item"><span class="${parentClasses}">...</span></li>`;
    };

    const getSubCategories = (categories = {}, categoryName = '', categoryInput = '', optionDefault = '') => {
      let subCategoriesAppend = '';
      subCategoriesAppend += `<option>${optionDefault}</option>`;
      Object.entries(categories).forEach(([key, category]) => {
        if (key === categoryName) {
          Object.entries(category).forEach((subCategory) => {
            const selected = categoryInput === subCategory[1].category_nicename ? 'selected' : '';
            subCategoriesAppend += `<option value="${subCategory[1].category_nicename}" ${selected}>${subCategory[1].name}</option>`;
          });
        }
      });

      return subCategoriesAppend;
    };

    const getPostHTML = (post = undefined, thumbnailsArr = [], buttonTxt = 'Więcej', publicationsArr = [], categoriesTags = [], excerpts = [], permalinks = []) => {
      let postHtml = '';
      if (post && thumbnailsArr) {
        // destructure post
        const { ID, post_title, post_type } = post;
        // get thumb url
        const thumbnailUrl = thumbnailsArr[ID] ? thumbnailsArr[ID] : '';
        const publicationDate = post_type !== 'page' && publicationsArr[ID] ? publicationsArr[ID] : '';
        const category = categoriesTags[ID] ? categoriesTags[ID] : '';
        const excerpt = excerpts[ID] ? excerpts[ID] : '';
        const permalink = permalinks[ID] ? permalinks[ID] : '';
        const firstColSize = thumbnailUrl ? 'col-xl-8' : 'col-xl-12';
        const secondColSize = thumbnailUrl ? 'col-xl-4' : 'col-xl-12';
        const colOrder = thumbnailUrl ? 'order-0' : 'order-1';

        // DOM structure post in feed
        postHtml =
          `<article class="entry" id="post-${ID}">
            <header class="entry-header">
                <h2 class="entry-title">
                    <a href="${permalink}" rel="bookmark">
                        ${post_title}
                    </a>    
                </h2>
                 <div class="entry-meta mb-0 mb-lg-4">
                    ${publicationDate}
                </div>
            </header>
            <div class="entry-summary">
                <div class="row">
                    <div class="${firstColSize} ${colOrder}">
                        <div class="entry-summary-desc">
                            ${excerpt}
                        </div>
                    </div>
                    <div class="${secondColSize}">   
                        <div class="entry-summary-img mb-0 mb-lg-4">
                            ${thumbnailUrl}
                        </div>
                    </div>
                </div>
            </div>
            <footer class="entry-footer">
                ${category}
                <div class="mt-4">
                    <a class="btn btn-primary" href="${permalink}">
                        ${buttonTxt}
                        <i class="fas fa-arrow-right ml-2"></i>
                    </a>
                </div>
            </footer>
        </article>`;
      }

      return postHtml;
    };

    // make ajax call to server
    const queryPosts = (element, category_name) => {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        // eslint-disable-next-line no-undef
        url: ajax_object.ajax_url,
        data: {
          action: 'applvr_paginate_posts',
          // eslint-disable-next-line no-undef
          nonce: ajax_object.nonce,
          paged,
          category_name,
          year,
          s,
          post_type,
          orderby,
          order,
          posts_per_page
        },
        beforeSend: function () {
          // clear feed & pagination
          $(element).find('.posts-feed').empty();
          $(element).find('.page-items').empty();
          // show spinner
          $(element).find($spinner).removeClass('d-none');
          // hide pagination
          $(element).find($pagination).addClass('d-none');
          // enable next/prev
          $(element).find('.page-item.arrow').removeClass('disabled');
        },
        success: function (response) {
          // destructure response
          const {
            max_num_pages,
            posts,
            thumbnails,
            button_txt,
            publications_date,
            categories_tags,
            categories,
            excerpts,
            permalinks,
            option_default,
            option_none
          } = response;
          let postsAppendValue = '';
          let pageAppendValue = '';

          if (posts.length) {
            // collect post DOM elements
            for (let index = 0; index < posts.length; index++) {
              const post = posts[index];
              postsAppendValue += getPostHTML(
                post,
                thumbnails,
                button_txt,
                publications_date,
                categories_tags,
                excerpts,
                permalinks,
                s
              );
            }

            // configure pagination
            if (max_num_pages && max_num_pages > 1) {
              // collect pagination DOM elements
              for (let index = 0; index < max_num_pages; index++) {
                const pageNum = index + 1;
                if (pageNum <= paged + 1 && pageNum >= paged) {
                  pageAppendValue += getPageHTML(pageNum);
                } else if (pageNum >= max_num_pages - 1) {
                  pageAppendValue += getPageHTML(pageNum);
                } else if (pageNum - 2 === paged) {
                  pageAppendValue += getPageDots();
                }
              }
              // disable next/prev if at end/beginning of pagination
              if (paged === max_num_pages) {
                $(element).find('.page-item.next').addClass('disabled');
              } else if (paged === 1) {
                $(element).find('.page-item.prev').addClass('disabled');
              }
              // show pagination
              $(element).find($pagination).removeClass('d-none');
            }
          } else {
            // show alert if no posts
            postsAppendValue = `<div class="section__title font-weight-bolder" role="alert">${option_none}</div>`;
          }

          // append to DOM
          if ($(element).find('.posts-feed').length) {
            $(element).find('.posts-feed').append(postsAppendValue);
          }
          if ($(element).find('.page-items').length) {
            $(element).find('.page-items').append(pageAppendValue);
          }

          $('.subcategory option').remove();
          $subContainer.addClass('d-none');
          $yearsContainer.addClass('d-block');

          if ($categoryInput.val() !== undefined && $categoryInput.val().trim() in categories) {
            $subContainer.removeClass('d-none');
            $subContainer.addClass('d-block');
            if ($('.subcategory').length) {
              $('.subcategory').append(
                getSubCategories(categories, $categoryInput.val().trim(), category_name, option_default)
              );
            }
          }
        },
        complete: function () {
          // hide spinner
          $(element).find($spinner).addClass('d-none');
        }
      });
    };

    // QUERY on load
    if (s) {
      post_type = ['post', 'page', 'monety'];
      orderby = '';
      order = '';
      category_name = '';
      queryPosts();
    } else {
      $mainContent.each((index, element) => {
        let category_name = $(element).data('category');
        queryPosts(element, category_name);
      });
    }

    // PAGINATION
    // pagination button
    $('body').on('click', '.page-item.num a', function (e) {
      e.preventDefault();
      const $this = $(this);

      // guard clause
      if ($this.parent().hasClass('active')) return false;
      // update paged val
      paged = parseInt($this.text());
      const parent = $(this).parent().parent().parent().parent().parent().parent().parent();
      category_name = $(parent).data('category') || category_name;

      // query posts
      generatePosts(parent, category_name);
    });

    // pagination input
    $pageInput.on('keypress', function (e) {
      if (e.which == 13) {
        pageInputTerm = $(this).val().trim();
        if (pageInputTerm) {
          e.preventDefault();
          const $this = $(this);

          // guard clause
          if ($this.parent().hasClass('active')) return false;
          // update paged val
          paged = parseInt(pageInputTerm);
          const parent = $(this).parent().parent().parent().parent().parent();
          category_name = $(parent).data('category') || category_name;

          // query posts
          generatePosts(parent, category_name);
        }
      }
    });

    // pagination arrow
    $('.page-item.arrow a').click(function (e) {
      e.preventDefault();

      if ($(this).parent().hasClass('prev')) {
        paged--;
      } else {
        paged++;
      }
      const parent = $(this).parent().parent().parent().parent().parent().parent();
      category_name = $(parent).data('category') || category_name;
      // query posts
      generatePosts(parent, category_name);
    });

    // FILTERS & SORT
    // order sorting
    $orderInput.change(function () {
      const $this = $(this);
      paged = 1;
      getSortValues($this.val().trim());
      const parent = $(this).parent().parent().parent().parent().parent().parent();
      category_name = $(parent).data('category') || category_name;
      console.log(parent);
      // query posts
      generatePosts(parent, category_name);
    });

    // category filter
    $categoryInput.change(function () {
      const $this = $(this);
      paged = 1;
      getSortValues($this.val().trim());
      category_name = $this.val().trim();
      const parent = $(this).parent().parent().parent().parent().parent().parent();
      // query posts
      generatePosts(parent, category_name);
    });

    // sub category filter
    $subCategoryInput.change(function () {
      const $this = $(this);
      paged = 1;
      const parent = $(this).parent().parent().parent().parent().parent().parent().parent();
      if ($this.val().trim() === 'Wybierz') {
        category_name = $(parent).data('category');
      } else {
        category_name = $this.val().trim() || category_name;
      }
      // query posts
      generatePosts(parent, category_name);
    });

    // years filter
    $yearInput.change(function () {
      const $this = $(this);
      paged = 1;
      year = $this.val().trim();
      const parent = $(this).parent().parent().parent().parent().parent().parent();
      category_name = $(parent).data('category') || category_name;
      // query posts
      generatePosts(parent, category_name);
    });
  }
});
